import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/login/forget'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/noPermissions',
    name: 'noPermissions',
    component: () => import('@/views/noPermissions/noPermissions'),
  },
  {
    path: '/home/ranking',
    name: 'home',
    component: () => import('@/views/home/ranking'),
  },
  {
    path: '/kline',
    name: 'home',
    component: () => import('@/views/home/kline'),
  },
  {
    path: '/placeorder',
    name: 'home',
    component: () => import('@/views/home/placeorder'),
  },
  {
    path: '/placeorder2',
    name: 'home',
    component: () => import('@/views/home/placeorder2'),
  },
  {
    path: '/remind',
    name: 'home',
    component: () => import('@/views/home/remind'),
  },
  {
    path: '/trade',
    name: 'trade',
    component: () => import('@/views/trade/trade'),
  },
  {
    path: '/trade/edit',
    name: 'trade',
    component: () => import('@/views/trade/edit'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/order'),
  },
  {
    path: '/order/kline',
    name: 'order',
    component: () => import('@/views/order/kline'),
  },
  {
    path: '/order/modify',
    name: 'order',
    component: () => import('@/views/order/modify'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/my'),
  },
  {
    path: '/my/news',
    name: 'my',
    component: () => import('@/views/my/news'),
  },
  {
    path: '/my/details',
    name: 'my',
    component: () => import('@/views/my/details'),
  },
  {
    path: '/my/message',
    name: 'my',
    component: () => import('@/views/my/message'),
  },
  {
    path: '/my/identity',
    name: 'my',
    component: () => import('@/views/my/identity'),
  },
  {
    path: '/my/accountMsg',
    name: 'my',
    component: () => import('@/views/my/accountMsg'),
  },
  {
    path: '/my/personal',
    name: 'my',
    component: () => import('@/views/my/personal'),
  },
  {
    path: '/my/record',
    name: 'my',
    component: () => import('@/views/my/record'),
  },
  {
    path: '/my/bill',
    name: 'my',
    component: () => import('@/views/my/bill'),
  },
  {
    path: '/my/welfare',
    name: 'my',
    component: () => import('@/views/my/welfare'),
  },
  {
    path: '/my/share',
    name: 'my',
    component: () => import('@/views/my/share'),
  },
  {
    path: '/my/feedback',
    name: 'my',
    component: () => import('@/views/my/feedback'),
  },
  {
    path: '/my/report',
    name: 'my',
    component: () => import('@/views/my/report'),
  },
  {
    path: '/my/wallet',
    name: 'my',
    component: () => import('@/views/my/wallet'),
  },
  {
    path: '/my/addWallet',
    name: 'my',
    component: () => import('@/views/my/addWallet'),
  },
  {
    path: '/my/withdraw',
    name: 'my',
    component: () => import('@/views/my/withdraw'),
  },
  {
    path: '/my/fundTransfer',
    name: 'my',
    component: () => import('@/views/my/fundTransfer'),
  },
  {
    path: '/my/transfer',
    name: 'my',
    component: () => import('@/views/my/transfer'),
  },
  {
    path: '/my/assets',
    name: 'my',
    component: () => import('@/views/my/assets'),
  },
  {
    path: '/my/loan',
    name: 'my',
    component: () => import('@/views/my/loan'),
  },
  {
    path: '/my/loan/record',
    name: 'my',
    component: () => import('@/views/my/loan/record'),
  },
  {
    path: '/my/loan/history',
    name: 'my',
    component: () => import('@/views/my/loan/history'),
  },
  {
    path: '/my/report/report_trade',
    name: 'my',
    component: () => import('@/views/my/report/report_trade'),
  },
  {
    path: '/my/report/report_account',
    name: 'my',
    component: () => import('@/views/my/report/report_account'),
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/setting'),
  },
  {
    path: '/setting/security',
    name: 'setting',
    component: () => import('@/views/setting/security'),
  },
  {
    path: '/setting/security/verify_password',
    name: 'setting',
    component: () => import('@/views/setting/security/verify_password'),
  },
  {
    path: '/setting/security/verify_phone',
    name: 'setting',
    component: () => import('@/views/setting/security/verify_phone'),
  },
  {
    path: '/setting/security/verify_email',
    name: 'setting',
    component: () => import('@/views/setting/security/verify_email'),
  },
  {
    path: '/setting/security/modify_password',
    name: 'setting',
    component: () => import('@/views/setting/security/modify_password'),
  },
  {
    path: '/setting/security/modify_pay',
    name: 'setting',
    component: () => import('@/views/setting/security/modify_pay'),
  },
  {
    path: '/setting/market',
    name: 'setting',
    component: () => import('@/views/setting/market'),
  },
  {
    path: '/setting/trade',
    name: 'setting',
    component: () => import('@/views/setting/trade'),
  },
  {
    path: '/setting/language',
    name: 'setting',
    component: () => import('@/views/setting/language'),
  },
  {
    path: '/setting/theme',
    name: 'setting',
    component: () => import('@/views/setting/theme'),
  },
  {
    path: '/setting/notice',
    name: 'setting',
    component: () => import('@/views/setting/notice'),
  },
  {
    path: '/setting/protocol',
    name: 'setting',
    component: () => import('@/views/setting/protocol'),
  },
  {
    path: '/setting/tradenews',
    name: 'setting',
    component: () => import('@/views/setting/tradenews'),
  },
  {
    path: '/setting/row2Details',
    name: 'setting',
    component: () => import('@/views/setting/tradenews/row2Details'),
  },
  {
    path: '/lockming',
    name: 'lockming',
    component: () => import('@/views/lockming/lockming'),
  },
  {
    path: '/lock-confirm',
    name: 'lock-confirm',
    component: () => import('@/views/lockming/confirm'),
  },
  {
    path: '/lock-order',
    name: 'lock-order',
    component: () => import('@/views/lockming/order'),
  },
]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

const router = new VueRouter({
  routes
})

export default router
