export default {
    common: {
        expected: "Ingresos esperados",
        view: "Ver",
        confirm: "Determinar",
        cancel: "Cancelación",
        deposit: "Entrada de oro",
        withdraw: "Salir de oro",
        noData: "No hay datos por el momento",
        submit: "Presentación",
        modify: "Modificación",
        hand: "Mano",
        confirm_verify: "Confirmación y verificación",
        next: "Siguiente paso",
        complete: "Completado",
        delete: "Eliminar",
		countryCode: "Código de país / región",
        no_balance: "Saldo insuficiente de la cuenta",
    },
    currency: {
		btcusdt: "Bitcoin (moneda virtual)",
		ethusdt: "Etherlands",
		ltcusdt: "Litecoin (moneda virtual)",
		xrpusdt: "Ripple (moneda)",
		adausdt: "Moneda Ada (utilizada en fracciones de moneda)",
		eosusdt: "Pomelo Coin",
		dotusdt: "Boca Ratón (antes Boca Ratón), moneda rusa",
		trxusdt: "Moneda de la tierra de cultivo de olas (informática)",
		xmrusdt: "Moneda Monroe (por ejemplo, dólar estadounidense)",
		dogeusdt: "Moneda perruna",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Dólar australiano Dólar estadounidense",
		fx_sgbpusd: "Libra-dólar",
		fx_seurusd: "Eurodólar",
		fx_snzdusd: "Dólar neozelandés (NZD) Dólar estadounidense",
		fx_susdcad: "Dólar estadounidense-Dólar canadiense",
		fx_susdchf: "Dólar estadounidense Franco suizo",
		fx_susdjpy: "Dólar estadounidense-yen",
		fx_saudcad: "Dólar australiano Dólar canadiense",
		fx_saudchf: "Dólar australiano y franco suizo",
		fx_saudjpy: "Dólar australiano Yen japonés",
		fx_saudnzd: "Dólar australiano Dólar neozelandés (NZD)",
		fx_scadchf: "Dólar canadiense Franco suizo",
		fx_scadjpy: "Dólar canadiense Yen japonés",
		fx_schfjpy: "Franco suizo Yen",
		fx_seuraud: "Euro Dólar australiano",
		fx_seurcad: "Euro Dólar canadiense",
		fx_seurgbp: "Euro Libra esterlina",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro Dólar neozelandés (NZD)",
		fx_sgbpaud: "Libra esterlina Dólar australiano",
		fx_sgbpchf: "Libra esterlina Franco suizo",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "Libra Dólar neozelandés (NZD)",
		fx_scadnzd: "Dólar canadiense Dólar neozelandés",
		hf_CL: "Petróleo WTI Nueva York",
		hf_OIL: "Petróleo Brent",
		hf_GC: "Oro de Nueva York",
		hf_SI: "Plata de Nueva York",
		hf_HG: "Cobre (préstamo)",
		hf_NG: "Estados Unidos Gas natural",
		hf_CAD: "Cobre (préstamo)",
		hf_AHD: "Londres aluminio",
		HX_AAPL: "Granada",
		HX_BABA: "Alí Babá, personaje de Las mil y una noches",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (minorista)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), inventor e ingeniero serbio",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Alemania",
		HX_IBEX: "IBEX35, España",
		HX_SPX: "Indice S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Corea",
		HX_NZ50: "Nueva Zelanda 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "Londres Zinc",
		hf_NID: "Níquel de Londres",
		hf_PBD: "Plomo de Londres",
		hf_SND: "Lata de Londres",
		hf_XAU: "Oro de Londres (oro al contado)",
		hf_XAG: "Plata de Londres (plata al contado)",
		hf_XPT: "Futuros del platino",
		hf_XPD: "Futuros del paladio",
		hf_CT: "Algodón estadounidense",
		hf_SM: "Soja en polvo",
		hf_BO: "Aceite de soja estadounidense",
		HX_SENSEX: "SENSEX, Bombay, India",
		HX_KLSE: "FTSE Malasia KLCI",
		HX_KSE100: "Karachi, Pakistán",
		HX_FCHI: "CAC40, Francia",
		HX_SX5E: "Snooker Europeo 50",
		HX_ICEXI: "ICEX, Islandia",
		HX_ASE: "ASE Atenas, Grecia",
		HX_OMXC20: "OMX Copenhague 20",
		HX_OSEBX: "OSEBX, Noruega",
		HX_OMXSPI: "OMXSPI, Suecia",
		HX_AMD: "Semiconductores Chaowei",
		HX_DIS: "Disney (nombre)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (marca)",
		HX_NVDA: "NVIDIA, empresa de tarjetas gráficas",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Tecnología Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "Seleccione por favor uma moeda",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dólar',
        currenctType15: 'Dólares de Hong Kong',
        currenctType16: 'Moeda de Taiwan',
        currenctType17: 'myr',
        currenctType18: 'Nova moeda',
        currenctType19: 'Iene japonês',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Rupiah Indonésia',
        currenctType23: 'libra',
        currenctType24: 'Baht tailandês',
        currenctType25: 'Cad',
        currenctType26: 'Dong vietnamita',
        currenctType27: 'Won coreano',
        currenctType28: 'Macau Pataca',
    },
    addnew3: {
        direction: "Dirección",
        sell_price: "precio de la orden",
        number: "cantidad",
        type: "Plazo de entrega",
        buy_price: "Precio_oferta",
        profit: "beneficio/pérdida",
        sell_time: "hora_venta",
    },
    addnew2: {
        address: {
            account_type: "Account Type",
            ifsc_code: "Código IFSC",
            title: "Dirección de la cartera",
            account_number: "Número de cuenta",
            add_btn: "Añadir dirección",
            label_type: "Seleccionar tipo",
            place_type: "Seleccione el tipo",
            label_number: "Número de cuenta",
            place_number: "Introduzca el número de cuenta",
            wallet_address: 'Dirección de la billetera',
            bank_name: 'Nombre del Banco',
            bank_address: "Dirección bancaria",
            name: "Nombre",
            payee_address: 'Dirección del beneficiario',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nombre de la sucursal",
            branch_code: "Código de la sucursal",
            bank_code: "Código bancario",
            phone: "Número de teléfono móvil",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Por favor, introduzca el contenido",
        },
    },
    addnew: {
        realFirst: "Por favor, realice la autenticación de identidad primero.",
        payFirst: "Por favor, establezca primero la contraseña de la transacción.",
        password1: "Modificar la contraseña de inicio de sesión",
        password2: "Modificar la contraseña de retiro",
        paypassTit1: "Por favor, establezca su contraseña de retiro.",
        oldPass: "Contraseña antigua",
        allView: "Todo leído",
        confirmOrder: "Confirmar pedido",
        name: "Nombre",
        fee: "Comisiones",
        orderCycle: "Elegir el tiempo",
        loan: "Elegir el tiempo",
        loanTit: "Préstamos asistidos",
        loanHostory: "Registros de préstamos",
        labelProductTitle: "Nombre del producto",
        immediateRepayment: "Reembolso inmediato",
        loadAmount: "Importe previsto del préstamo",
        RepaymentCycle: "Ciclo de reembolso del préstamo",
        day: "Días",
        dailyRate: "Tasa de interés diaria",
        repaymentMethod: "Método de reembolso",
        lendingCompany: "Empresas de préstamos",
        loanTip: "Préstamos de crédito (asegúrese de que la imagen sea claramente visible)",
        loanTip1: "Cargar certificado de propiedad",
        loanTip2: "Certificado de ingresos (relación laboral)",
        loanTip3: "Detalles de la factura bancaria",
        loanTip4: "Sube la foto frontal de la tarjeta de identificación",
        interest: "Intereses",
        repaid: "Ha sido reembolsado",
        unpaid: "No reembolsado",
        tab1: "Monedas y monedas",
        tab2: "Contrato",
        tab3: "Opciones",
        available: "Cuota disponible",
        pending: "Bloqueo",
        equivalent: "Equivalente",
        fundTransfer: "Transferencia de fondos",
        flashExchange: "Cambio flash",
        select_breed: "Elegir la moneda",
        from: "De",
        to: "A",
        transferQuantity: "Número de transferencias",
        repaymentMethod1:"Reembolso único al vencimiento"
    },
    params: {
        product_foreign: "Forex",
        product_shop: "Materias primas",
        product_number: "Índices",
        product_stock: "Acciones",
        product_coin: "Coin",
        billTypes: [
            { v: 101, name: 'Recargar' },
            { v: 102, name: 'Añadir' },
            { v: 103, name: 'Congelación' },
            { v: 104, name: 'Descongelación' },
            {v: 105, name: 'Depósito'},
            {v: 106, name: 'Depósito'},
            { v: 201, name: 'Congelación de retiros de efectivo' },
            { v: 202, name: 'Deducción' },
            { v: 203, name: 'Retiro exitoso' },
            { v: 204, name: 'Falló el retiro de efectivo' },
            { v: 205, name: 'Comisión de retiro' },
            {v: 206, name: 'Traslado'},
            {v: 207, name: 'Transferencia'},
            {v: 208, name: 'Transferencia de moneda y moneda'},
            {v: 209, name: 'Transferencia de moneda y moneda'},
            { v: 301, name: 'Deducción de la fianza' },
            { v: 302, name: 'Deducción de Comisiones' },
            { v: 303, name: 'Devolución de la fianza' },
            { v: 304, name: 'Ganancias' },
            { v: 305, name: 'Pérdidas' },
            {v: 311, name: 'Opciones de compra'},
            {v: 312, name: 'Ganancias de opciones'},
            {v: 313, name: 'Devolución de opciones'},
            {v: 314, name: 'Comisión de opciones'},
            {v: 315, name: 'Congelación de la compra de monedas y monedas'},
            {v: 316, name: 'Deducción de compra de moneda y moneda'},
            {v: 317, name: 'Compra y devolución de monedas y monedas'},
            {v: 318, name: 'La moneda se compra en la cuenta'},
            {v: 319, name: 'La moneda se compra en la cuenta'},
            {v: 320, name: 'Congelación de la venta de monedas y monedas'},
            {v: 321, name: 'Deducción por la venta de monedas y monedas'},
            {v: 322, name: 'Moneda vendida y devuelta'},
            {v: 323, name: 'Las monedas se venden a la cuenta'},
            {v: 324, name: 'Las monedas se venden a la cuenta'},
            {v: 325, name: 'Tarifa de manejo de monedas y monedas'},
            {v: 401, name: 'Se unen las máquinas mineras'},
            {v: 402, name: 'Retorno de la máquina minera'},
            {v: 403, name: 'Ingresos por máquinas mineras'},
            {v: 404, name: 'Retirada de la máquina minera'},
            {v: 405, name: 'Tarifa de salida de la máquina minera'},
            {v: 411, name: 'Ingresos por préstamos'},
            {v: 412, name: 'Comisiones de préstamo'},
            {v: 413, name: 'Reembolso exitoso'},
        ],
    },
    lockming: {
        profit: "Ganancias recientes (diarias)",
        cycle: "Ciclo financiero",
        limit: "Límite único",
        dividend_time: "Tiempo de pago de dividendos",
        every_day: "diario",
        funds: "Fondos en custodia",
        back: "Retorno al vencimiento",
        ransom: "Rescate anticipado",
        label_amount: "Importe de la garantía",
        place_amount: "Por favor, introduzca,,,",
        available: "Disponible",
        expect_income: "Ingresos previstos",
        title: "Minería para ingresos",
        tip: "Minado para obtener ingresos",
        label_today_income: "Ingresos estimados hoy",
        label_total_income: "Ingresos acumulados",
        label_order: "Pedidos en custodia",
        label_number: "Importe único",
        daily_return: "Rentabilidad diaria",
        subscribe: "Suscripción",
        position: "Posición",
        label_state: "Estado",
        subscribe_time: "Hora de suscripción",
        expiration_time: "tiempo de expiración",
        income: "ingreso",
        errorTip1: "Por favor, introduzca la cantidad",
        tip_tit: "¿¿ está seguro de que quiere retirarse antes de lo previsto?",
    },
    footer: {
        nav1: "Página de inicio",
        nav2: "Transacciones",
        nav3: "Posición",
        nav4: "El mío",
        kline_set1: "Configuración del mercado",
        kline_set2: "Configuración de la transacción",
    },
    login: {
        login: "Iniciar sesión",
        register: "Registro",
        type1: "Número de teléfono móvil",
        type2: "Buzón",
        login_btn: "Inicio de sesión seguro",
        register_btn: "Registrarse de inmediato",
        psd_login: "Inicio de sesión con contraseña",
        verify_login: "Iniciar sesión con el Código de verificación",
        forget: "Olvida la contraseña?",
        no_account: "Sin cuenta？",
        have_account: "Cuenta existente",
    },
    home: {
        home: {
            nav1: "Clasificación de subidas y bajadas",
            nav2: "Entrada rápida",
            nav3: "Sobre nosotros",
            nav4: "Recompensa por invitación",
            most_follow: "Atención máxima",
            news_type1: "Estrategia",
            news_type2: "Noticias",
            news_type3: "Calendario financiero",
            news_tab1: "Todo",
            news_tab2: "Divisas",
            news_tab3: "Acciones",
            news_tab4: "Productos Básicos",
            news_tab5: "Índice",
            news_tab6: "Datos",
            news_tab7: "Incidencias",
            news_tab8: "Vacaciones",
            label_previous: "Valor anterior",
            label_predictive: "Valor predictivo",
            label_announced: "Valor publicado",
        },
        kilne: {
            label_bp: "Recibido ayer",
            label_sp: "Abierto hoy",
            label_height: "Máximo",
            label_low: "Mínimo",
            tip: "Hi, soy el asistente de variedades de Richardgpt",
            row1_tit: "Estrategia",
            row1_rinei: "Durante el día",
            row1_short: "A corto plazo",
            row1_middle: "A medio plazo",
            row1_time: "Tiempo de lanzamiento",
            row1_tit2: "Estrategia comercial",
            data_sources: "Fuentes de datos",
            row2_tit1: "Datos",
            row2_tit2: "Emoción transaccional",
            row2_label_sell: "Vendedor",
            row2_label_buy: "Compradores",
            tip2: "Solo para referencia y no como posición de la empresa",
            tip3: "Actualización cada 15 minutos",
            row2_tit3: "Los precios suben y bajan",
            minute: "Min",
            today: "Hoy.",
            right_tit7: "Rango de variación de precios",
            now_price: "Precios actuales",
            right_low_price: "Precio más bajo",
            right_height_price: "Precio máximo",
            details: "Detalles",
            right_label1: "Número de transacciones individuales",
            right_label2: "Apalancamiento máximo",
            right_label3: "Tamaño del contrato",
            right_label4: "Número máximo de posiciones totales",
            right_label5: "Diferencial flotante",
            right_label6: "Tarifa nocturna",
            hand: "Mano",
            right_sell: "Vender",
            right_buy: "Comprar",
            right_collection_time: "Tiempo de cobro",
            right_tip3: "Cobrado de todo el tamaño de la transacción",
            right_label9: "Período de negociación",
            right_label10: "Período actual",
            right_label11: "Siguiente periodo",
        },
        placeorder: {
            direction_buyPrice: "Precio de compra",
            direction_sellPrice: "Precio de venta",
            direction_sell: "Vender",
            direction_buy: "Comprar",
            market_price: "Lista de precios de mercado",
            check_price: "Lista",
            sell_tip: "Vender cuando el precio cumple las condiciones",
            buy_tip: "Compra cuando el precio cumple las condiciones",
            trade_number: "Número de transacciones",
            hand: "Mano",
            select_lever: "Apalancamiento selectivo",
            right_tip: "Se debe pagar una fianza",
            balance: "Saldo disponible",
            label_stop_win_price: "Detener las ganancias",
            label_stop_lose_price: "Stop loss",
            expected_profit_loss: "Ganancias y pérdidas estimadas",
            trailing_stop: "Rastrear Stop loss",
        },
        ranking: {
            tit: "Clasificación de subidas y bajadas del día",
            p: "Capturar puntos calientes y aprovechar oportunidades",
            renew: "Actualizado en",
            growth_chart: "Lista de subidas",
            drop_list: "Lista de caídas",
            show_closed_market: "Mostrar la marca cerrada",
            filter1: "Todo",
            filter2: "Divisas",
            filter3: "Productos Básicos",
            filter4: "Acciones",
            filter5: "Índice",
            filter6: "Cifrado",
            td1: "Clasificación",
            td2: "Tendencias",
            td3: "Aumento / precio de venta del día",
        },
        remind: {
            now_sell_price: "Precio actual de venta",
            direction_buyPrice: "Precio de compra",
            direction_sellPrice: "Precio de venta",
            direction_sell: "Vender",
            direction_buy: "Comprar",
            equal: "Cuando",
            p: "Recuérdame cuando alcance el siguiente precio",
            btn1: "Establecer recordatorio",
            list_tit: "Lista de recordatorios",
        },
    },
    my: {
        report: {
            tit: "Informe de la cuenta",
            tab1: "Informe",
            tab2: "Declaración diaria",
            tab3: "Declaración mensual",
            history_tit: "Informe sobre el historial de transacciones",
            account_tit: "Informe de la cuenta",
            filter: "Selección",
            tip1: "Por favor, elija el rango de fecha adecuado para exportar su informe de historia de transacciones。",
            tip2: "Tiempo personalizado",
            email_send: "Enviar al buzón",
        },
        accountMsg: {
            tit: "Información de la cuenta",
            label1: "Información personal",
        },
        bill: {
            tit: "Detalles del flujo de fondos",
            all: "Todo",
            time: "Tiempo",
        },
        feedback: {
            tit: "Recomendaciones funcionales",
        },
        identity: {
            tit: "Autenticación",
            type1: "Tarjeta de identificación",
            type2: "Licencia de conducir",
            type3: "Pasaporte",
            tip: "Confirmo que toda la información y documentación proporcionada es completa, verdadera y precisa.",
        },
        my: {
            is: "Ya",
            no: "No verificado",
            verify: "Verificado",
            id_verify: "Autenticación",
            verify_tip: "Completar la autenticación para ingresar a la transacción de oro",
            no_verify: "Verificación inmediata",
            balance: "Saldo disponible",
            profit_loss: "Ganancias y pérdidas",
            marginLevel: "Nivel de margen",
            btn1: "Cambiar a cuenta real",
            deposit_withdraw_record: "Registro de entrada y salida",
            capital_flow_details: "Detalles del flujo de fondos",
            welfare_center: "Centros de Bienestar Social",
            tip1: "Usted tiene",
            tip2: "La recompensa está pendiente de recibir",
            feedback: "Recomendaciones funcionales",
            report: "Informe de la cuenta",
            setting: "Configuración",
        },
        news: {
            tit: "Aviso",
        },
        record: {
            tit: "Registro de entrada y salida",
            cycle: "Ciclo",
        },
        share: {
            tit: "Invitar a amigos",
            invite_success: "Invitar con éxito a amigos",
            tip1: "Recompensas en efectivo",
            tip2: "Invitar a los amigos a tomar lo más alto",
            tip3: "Invitar a amigos a recibir efectivo",
        },
        welfare: {
            tit: "Centros de Bienestar Social",
            tab1: "Rollo de tarjeta",
            tab2: "Actividades",
            history_record: "Registros históricos",
            period_validity: "Período de validez",
        },
        wallet: {
            tit: "Billetera",
            addNew: "Añadir una nueva billetera",
        },
        withdraw: {
            tit: "Salir de oro",
            addNew: "Añadir una nueva billetera",
        },
    },
    order: {
        demo_account: "Cuenta simulada",
        real_account: "Cuenta real",
        tip: "Las transacciones de entrada de oro se pueden realizar después de la apertura.",
        tab1: "Posiciones",
        tab2: "Lista",
        tab3: "Historia",
        list_empty: "Actualmente no hay lista",
        start_trade: "Iniciar la transacción",
        hand_tip: "Rango de entrada",
        kline: {
            direction_sell: "Vender",
            direction_buy: "Comprar",
            open_price: "Precio de apertura",
            now_price: "Precios actuales",
            trade_account: "Cuenta de transacción",
            demo_account: "Cuenta simulada",
            order_id: "ID del pedido",
            open_time: "Hora de apertura",
            stop_profit: "Detener las ganancias",
            stop_loss: "Stop loss",
            trailing_stop: "Rastrear Stop loss",
            float_profit_loss: "Ganancias y pérdidas flotantes",
            overnight_fee: "Tarifa nocturna",
            margin: "Margen",
            maintainsMargin: "Mantener la fianza",
            open_value: "Valor de apertura",
            now_value: "Valor actual",
            notset: "No establecido",
            close_position: "Cierre de posiciones",
            close_number: "Cantidad habitual",
            onclick_close: "Cierre de posiciones con un solo clic",
            btn: "Confirmación de la liquidación de posiciones",
            balance: "Saldo disponible",
            profit_loss: "Ganancias y pérdidas",
            netValue: "Valor neto",
            marginLevel: "Nivel de margen",
        },
        modify: {
            tit: "Modificar la lista de posiciones",
            expected_profit_loss: "Ganancias y pérdidas estimadas",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Establecer una nueva contraseña",
            verify_email_tit: "Verificar el buzón",
            verify_password_tit: "Verifique su contraseña",
            verify_password_tip: "Introduzca su contraseña de inicio de sesión de Richard para continuar",
            verify_phone_tit: "Verificar el número de teléfono móvil",
            tit: "Seguridad de la cuenta",
            tip: "Para proteger la seguridad de su cuenta y aceptar nuestra notificación de servicio, se recomienda abrir al menos una autenticación.",
            label1: "Modificar la contraseña",
            label2: "Cancelación de cuentas",
            label3: "Doble verificación",
            label4: "Gestión de equipos",
            tip2: "Se necesita verificar al iniciar sesión en el nuevo dispositivo",
        },
        tradenews: {
            tit: "Guía de transacciones",
            tab1: "Entrada rápida",
            tab2: "Aprendizaje avanzado",
            tab3: "Centro de ayuda",
            tab4: "Sobre Richard",
            viewMore: "Ver más",

            details: {
                tit: "Introducción a las divisas",
                tip1: "Duración estimada del aprendizaje",
                tip2: "Min",
            },
            tab1Row: {
                tit: "Es una clase que aprende rápidamente a comerciar con Richard",
                tit1: "¿¿ cómo se negocia en Richard?",
                tit2: "Cómo abrir una cuenta real？",
                tit3: "Cómo abrir una transacción de almacén？",
                tit4: "Cómo establecer ganancias y pérdidas？",
                tit5: "Cómo cerrar posiciones？",
                p1: "Puede negociar el objetivo comprando o vendiendo. Abrir una posición en Richard significa que tiene",
                p2: "Las fluctuaciones de precios del producto subyacente se compran y venden",
                p3: "Por ejemplo, si espera que el precio del oro suba, compra el producto; Por el contrario, si predice que el precio del oro caerá, venderá el producto. Si cumple con las expectativas en el futuro, puede",
                p4: "Beneficiarse de ello",
                p5: "Además, Richard ofrece ''transacciones apalancadas'. Puede usar el apalancamiento para comerciar con más objetivos con el mismo número de manos.",
                p6: "El apalancamiento amplificará las ganancias y las pérdidas.",
                p7: "Esto también se llama ''transacción de margen'. Cuando abra su posición, su cuenta de Richard deducirá un cierto saldo como",
                p8: "Margen inicial",
                p9: "Seleccionar productos y direcciones de compra y venta",
                p10: "Transacciones",
                p11: "Haga clic en 'comprar /' vender para completar el pedido",
                p12: "Establecer 'stop loss' o 'stop loss''le puede ayudar",
                p13: "Bloquear las ganancias y limitar las pérdidas a tiempo",
                p14: "Puede hacer clic en la página de éxito de apertura",
                p15: "Detener ganancias / detener pérdidas",
                p16: "Para configurar, también puede hacer clic en ''modificar'si no se ha ajustado después para detener las ganancias y detener las pérdidas.",
                p17: "Configuración o cancelación",
                p18: "Puede ver las ganancias y pérdidas en ''posiciones cerradas'",
            },
            tab3Row: {
                h1: "Bienvenidos a Richard",
                h2: "Centro de ayuda",
                tit0: "Todas las preguntas",
                tit1: "Cuenta simulada",
                tit2: "Abrir una cuenta",
                tit3: "Entrada y salida de oro",
                tit4: "Configuración de la plataforma",
                tit5: "Operaciones comerciales",
                tit6: "Tarifas y tarifas",
                tit7: "Seguridad financiera y regulación",
                tit1Tit1: "Richard Si se proporcionan cuentas simuladas",
                tit1Cont1: "<p>Richard Proporciona cuentas simuladas para el funcionamiento del cliente, puede utilizar nuestra plataforma de red y aplicaciones móviles para transacciones sin ningún riesgo y familiarizarse con el funcionamiento de la plataforma.</p>",
                tit1Tit2: "Cómo crear una cuenta simulada?",
                tit1Cont2: "<p>Solo tiene que hacer clic aquí para rellenar su dirección de correo electrónico o número de teléfono móvil y configurar su contraseña personal para completar el registro y obtener una cuenta simulada.。</p>",
                tit1Tit3: "¿¿ de qué sirve una cuenta simulada??",
                tit1Cont3: "<p> las cuentas simuladas son las mismas que las cuentas reales en la mayoría de las interfaces de transacción, datos y operaciones. las cuentas simuladas tienen $50000 en fondos virtuales, con el objetivo de familiarizar a los clientes con las funciones de la plataforma a través de operaciones simuladas sin riesgo financiero. </p>",
                tit1Tit4: "Cómo inyectar capital en la cuenta simulada?",
                tit1Cont4: "<p>No necesita inyectar capital en la cuenta simulada，Cuando registró con éxito la cuenta simulada，Habrá 50.000 dólares estadounidenses en la cuenta o dólares australianos en dólares australianos.</p><p>Y cuando el valor neto de la cuenta simulada sea inferior a $200 USD o Aud de dólares australianos, el sistema reembolsará automáticamente los fondos de su cuenta a $50000 USD o Aud de dólares australianos.</p>",
                tit1Tit5: "Si la cuenta simulada tiene un período de uso?",
                tit1Cont5: "<p>La cuenta simulada es válida por 90 días. si no abre una cuenta real después de 90 días, la cuenta simulada se congelará y no podrá realizar transacciones, y se descongelará cuando abra una cuenta real.。</p><p>Y si abre una cuenta real en 90 días, la cuenta simulada será válida durante mucho tiempo. Incluso después de abrir la cuenta real, se puede continuar operando en la cuenta simulada.。</p>",
                tit2Tit1: "Richard ¿ Cuántos tipos de cuentas se ofrecen?  ",
                tit2Cont1: "<p>Solo ofrecemos cuentas de transacciones estándar y profesionales para individuos, y no admitimos la apertura de Cuentas corporativas y cuentas conjuntas.</p>",
                tit2Tit2: "¿¿ qué moneda puedo abrir una cuenta de transacción??",
                tit2Cont3: "<p>Cuando abra una cuenta, el sistema determinará automáticamente su moneda de liquidación en función de su región y no será modificable.</p>",
                tit2Tit4: "Cómo abrir una cuenta real?",
                tit2Cont4: "<p>Para abrir una cuenta real, siga los siguientes pasos.</p><p>1. Ingrese a la página de registro de Richard y siga las indicaciones: registre el número de teléfono móvil del buzón de correo electrónico, establezca la contraseña de inicio de sesión y cree automáticamente después de enviar la confirmación.</p><p></p><p></p>",
                tit3Tit1: "¿¿ cómo puedo ingresar el oro??",
                tit3Cont1: "<p>Richard Proporcionar diferentes formas de entrada para que los clientes elijan, incluyendo y puede ser más de los siguientes elementos:</p><p>1.Visa/Mastercard - Por lo general, la llegada inmediata a la cuenta</p><p>2.El pago bancario en línea generalmente se realiza una T antes del día.</p><p>3.Billeteras electrónicas (por ejemplo: skrill, momo, zalo.touch 'n go, boost, etc.). - Por lo general, la llegada inmediata a la cuenta</p><p>4.Tarjeta ATM-Por lo general, la llegada inmediata a la cuenta</p><p>5.QR Mobile Network Bank -Por lo general, la llegada inmediata a la cuenta</p>",
                tit3Tit2: "¿¿ puedo usar una cuenta bancaria / tarjeta a nombre de un tercero para ingresar o pagar dinero?",
                tit3Cont2: "<p>RichardNo se aceptan entradas de terceros. Por favor, use su cuenta bancaria personal / tarjeta a su nombre para ingresar o pagar. Si utiliza el nombre de otra persona o la cuenta bancaria / tarjeta de la empresa para ingresar el dinero, los fondos serán devueltos.</p>",
                tit3Tit3: "Cómo solicitar dinero de la cuenta de Richard?",
                tit3Cont3: "<p>Puede hacer clic en la salida de oro después de iniciar sesión en la plataforma del sitio web, seleccionar la cuenta bancaria o visa / MasterCard y rellenar el monto del oro, y luego presionar [enviar], después de determinar que la información es correcta, Richard procesará su solicitud dentro de 1 - 2 días hábiles.</p>",
                tit4Tit1: "Richard¿¿ cuántas veces se ofrecen transacciones apalancadas??",
                tit4Cont1: "<p>RichardEl múltiplo de apalancamiento proporcionado por la Plataforma se establece de acuerdo con las regulaciones de las autoridades reguladoras. para diferentes productos, su apalancamiento máximo también tiene diferentes detalles, por favor inicie sesión en la Plataforma de comercio para ver.。</p>",
                tit4Tit2: "Cómo cambiar mi contraseña?",
                tit4Cont2: "< p > puede reiniciar la nueva contraseña después de iniciar sesión en la plataforma seleccionando [más] - [configuración de seguridad] - - - T modificar la contraseña]</P>",
                tit4Tit3: "¿¿ cómo iniciar sesión en la Plataforma de comercio Richard?",
                tit4Cont3: "<p>App:Vaya a ''my' y haga clic en 'login L registration' en la parte superior izquierda para ingresar a la página de inicio de sesión.</p> web: haga clic en ''iniciar sesión'en la esquina superior derecha del sitio web oficial de Richard para ingresar a la página de inicio de sesión。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "¿¿ cómo se recuerda la plataforma??",
                tit4Cont4: "<p>Richard Recordatorio por correo electrónico, mensaje de texto, empuje de la plataforma。</p>",
                tit5Tit1: "¿¿ qué diferencia de precios ofrece Richard?",
                tit5Cont1: "<p>Cobramos una diferencia de precio bajo y no cobramos ninguna Comisión. Si la diferencia de precio es flotante, la diferencia de precio real cobrada dependerá de la variedad que negocie, dependiendo de la pantalla inmediata de la Plataforma de comercio.。</p>",
                tit5Tit2: "Cómo establecer una lista de precios de mercado?",
                tit5Cont2: "<p>Haga clic en [transacción] (comercio) para mostrar todos los productos que la Plataforma puede proporcionar para la transacción, puede introducir el Código de variedad o el nombre en el cuadro de entrada a la derecha para buscar, y elegir un producto haga clic en [comprar / vender] sell / buy) para abrir la ventana de transacción, en la que puede ver el precio actual y el margen esperado. Puede ajustar manualmente el número de transacciones de apertura de posiciones y establecer ganancias y paradas para el control de riesgos.</p>",
                tit5Tit3: "Cómo establecer un listado?",
                tit5Cont3: "<p>Al seleccionar un producto en [comercio] (comercio), haga clic en [comprar / vender] (sell / buy) aparecerá la ventana de negociación, en la ventana de negociación</p>",
                tit5Tit4: "Cómo cerrar posiciones?",
                tit5Cont4: "<p>Para cerrar la posición, haga clic en [abrir] en la plataforma dentro de la posición r, luego haga clic en la posición que desea cerrar la posición, y luego haga clic en el botón [cerrar L (cerca) a la derecha.</p>",
                tit5Tit5: "Cómo modificar o eliminar el pedido?",
                tit5Cont5: "<p>Para modificar o eliminar el listado, haga clic en [listados] (pedidos) en la plataforma en [posición] y luego haga clic en el listado que desea modificar o eliminar en curso.</p>",
                tit6Tit1: "¿¿ se cobrará una Comisión por usar la Plataforma de comercio??",
                tit6Cont1: "<p>Richard Es una plataforma de comercio completamente libre de Comisiones. Cobramos una diferencia de precio bajo, dependiendo de su situación comercial específica, la Plataforma puede generar tarifas adicionales, como intereses a la noche.。</p>",
                tit6Tit2: "¿¿ hay algún costo en la entrada??",
                tit6Cont2: "<p>No cobraremos ninguna tarifa relacionada con la entrada al cliente, pero su banco de pago o tránsito puede cobrar una tarifa de manejo. Le recomendamos que pregunte a los bancos relevantes si le cobran alguna tarifa.。</p>",
                tit6Tit3: "¿¿ hay algún costo para pagar el dinero??",
                tit6Cont3: "<p>Richard No se cobrará a los clientes ninguna tarifa relacionada con el pago a menos que el monto de su pago sea inferior al mínimo o supere el número máximo de pagos gratuitos en el mes. Para más detalles, consulte nuestra política de salida de oro.。</p>",
                tit7Tit1: "¿¿ los fondos que deposité en la cuenta de Richard se utilizarán para otros fines?",
                tit7Cont1: "<p>No lo hará. Los depósitos de los clientes minoristas se depositan por separado en cuentas fiduciarias cuando sea necesario de acuerdo con las regulaciones regulatorias. Al depositar fondos en la cuenta de pago del cliente o retirar fondos de la cuenta de pago del cliente o hacer pagos, Richard cumple estrictamente con las normas regulatorias sobre el pago del cliente.</p>",
                tit7Tit2: "¿Si Richard cierra o está endeudado, ¿ mis fondos también se ven afectados?",
                tit7Cont2: "<p>Los depósitos de los clientes minoristas se depositan por separado en cuentas fiduciarias cuando sea necesario de acuerdo con las regulaciones regulatorias. En caso de quiebra de Richard, orden de cancelación de la licencia o cualquier circunstancia que no pueda continuar operando, los fondos del cliente todavía pueden ser aislados y limitados por la regulación de la regulación de fondos del cliente en la Ley reguladora.</p>",
                tit7Tit3: "RichardSi cumple con las disposiciones legales?",
                tit7Cont3: "<p>La compañía cumple plenamente con las disposiciones pertinentes de la Ley de sociedades y la Ley de supervisión financiera, y es un proveedor de servicios financieros autorizado y estrictamente supervisado por los reguladores pertinentes. De acuerdo con los estrictos requisitos regulatorios, la compañía proporciona una garantía integral y suficiente para la seguridad financiera de los clientes.。</p>",
                tit7Tit4: "¿¿ los datos personales de los clientes son seguros??",
                tit7Cont4: "<p>Richard cumple estrictamente con las regulaciones de protección de datos personales, y los datos personales de los clientes nunca se filtran. La compañía se asegurará de que los empleados cumplan con los estándares rigurosos de Seguridad y confidencialidad estipulados por la ley y presten gran atención a los arreglos de confidencialidad y uso de los datos de los clientes. Para más detalles, consulte los documentos de divulgación legal de la compañía.。</p>",
            },
            tab4Row: {
                span: "Q método de verificación",
                p1: "Richard es la respuesta para aquellos que buscan plataformas profesionales de derivados de criptomonedas. Nuestro objetivo es crear un mercado eficiente y justo que conecte a los comerciantes de todos los orígenes y estilos de negociación. La alta capacidad del motor de emparejamiento, la baja latencia, la avanzada gestión de riesgos y la alta liquidez hacen de Richard un participante único en el mercado。",
                p2: "El objetivo inicial de Richard era crear la primera bolsa de opciones de criptomonedas del mundo. Aunque esta es una tarea muy desafiante, en más de dos años, el equipo ha completado el desarrollo del sistema. En junio de 2016, Richard se lanzó oficialmente。",
                p3: "Richard is composed of a senior team with rich experience and knowledge in financial trading and fintech industries, founded in Melbourne, Australia. Richard's philosophy is to make trading easier and more friendly.",
                p4: "En la actualidad, nuestros clientes pueden negociar contratos perpetuos, contratos de futuros y contratos de opciones. Richard está creciendo rápidamente y es una de las principales bolsas que ofrece futuros de criptomonedas y transacciones de contratos permanentes. Además, Richard sigue siendo la bolsa líder que ofrece opciones de criptomonedas de liquidación de efectivo al estilo europeo y continúa estableciendo estándares para el sector。",
                p5: "Richard Holdings está autorizada y regulada por la Autoridad Monetaria de las Islas Caimán (cima), con el número de licencia SIB 1612446. Para obtener más detalles sobre la licencia de caimán, consulte el sitio web oficial de la cima www.cima.ky..",
                p6: ") Haga una consulta. Richardglobal posee una licencia de servicio financiero autorizada por la Comisión Australiana de valores e inversiones (asic) (afsl 398528). Para obtener más detalles sobre la licencia australiana, consulte el sitio web oficial de asic www.asic.gov.au. (",
                p7: ") Haga una consulta. Richard International está autorizado y regulado por la Comisión de servicios financieros de Mauricio (fsc), con el número de licencia gb20025791. Para obtener más información sobre la licencia de mauricio, puede consultar el sitio web oficial de FSC www.fscmuritius.org.(",
                p8: ")Haga una consulta. Todas las operaciones comerciales de Richard se llevan a cabo bajo estricta supervisión y cumplen con todas las regulaciones.",
                p9: "Antecedentes",
                p10: "Richard, formado por un equipo veterano con experiencia e inteligencia en transacciones financieras y la industria de la tecnología financiera, fue fundada en melbourne, Australia.",
                p11: "La filosofía de Richard es que las transacciones se vuelvan más sencillas y amigables. Nuestra estrategia holística y nuestro modelo de negocio nos permiten tener una observación y comprensión global y profunda del desarrollo de la industria y la demanda del mercado, lo que permite asignar recursos de manera más específica, innovar constantemente las tecnologías y optimizar la eficiencia para seguir trayendo a los clientes una experiencia comercial más conveniente y amigable.",
                p12: "¿¿ por qué elegir?",
                p13: "Condiciones de negociación de umbral bajo",
                p14: "Plataforma de comercio simple e intuitiva",
                p15: "Costos de transacción competitivos",
                p16: "Regulado por la autoridad de la industria",
                p17: "Soporte en línea de alto nivel",
                p18: "Protección del saldo negativo",
                p19: "Premios y honores",
                p20: "Richard tiene el gran honor de recibir varios honores como [el mejor corredor de múltiples activos] de World Finance Awards y [la mejor plataforma de comercio móvil de la región Asia - pacífico] [el corredor de tecnología financiera de más rápido crecimiento del mundo] de Global Brands magazine, todo lo cual afirma el esfuerzo y el esfuerzo del equipo.",
                p21: "Mejor corredor de activos diversificados",
                p22: "Filosofía operativa",
                p23: "Nuestros valores",
                p24: "Somos una de las plataformas de comercio de criptomonedas de más rápido crecimiento del mundo y creemos que nuestros valores centrales son la fuerza impulsora de este éxito.。",
                p25: "Honestidad e integridad",
                p26: "La confianza de los usuarios es crucial; Por lo tanto, hacemos negocios con los más altos estándares de integridad proporcionando sistemas de gestión de riesgos justos, tratando a todos los participantes en el mercado por igual y soluciones de Seguridad avanzadas.。",
                p27: "Calidad del servicio",
                p28: "Las transacciones de criptomonedas son un proceso sin fronteras y las 24 horas del día, en el que la calidad del servicio no se ve comprometida. El cliente es nuestro valor más importante; Por lo tanto, nuestro objetivo es proporcionar una experiencia comercial ininterrumpida y fluida. Nuestro objetivo es lograr este objetivo ampliando y mejorando constantemente nuestra arquitectura del sistema, minimizando los tiempos de mantenimiento y proporcionando un servicio al cliente oportuno y multilingüe.。",
                p29: "Crear",
                p30: "En un entorno de criptomonedas de ritmo rápido, la innovación es necesaria. Richard es una empresa impulsada por soluciones diseñada para brindar la mejor experiencia comercial a millones de usuarios, un objetivo arraigado en nuestra arquitectura de sistemas escalables y de alto rendimiento.。",
                p31: "¿¿ por qué se eligen los derivados?？",
                p32: "Negociar derivados tiene muchos beneficios: costos de transacción más bajos, mayor apalancamiento, mayor liquidez y más fácil Venta corta。",
                p33: "Las transacciones de derivados también permiten estrategias comerciales más diversificadas. Los comerciantes pueden usar derivados para cubrir riesgos, arbitraje o transacciones especulativas。",
                p34: "Ciencia y tecnología",
                p35: "Para garantizar un sistema de alto rendimiento, se ha desarrollado durante más de dos años antes de su lanzamiento.。",
                p36: "El marco de la Plataforma se ha desarrollado para garantizar que se pueda procesar un gran número de solicitudes con baja latencia. Hemos desarrollado especialmente un motor de coincidencia único para Richard, y toda nuestra tecnología es exclusiva.。",
                p37: "Desde su puesta en marcha, nuestro sistema de liquidación incremental y gestión de riesgos garantiza cero pérdidas socializadas。",
                p38: "En Richard creemos en el futuro de las criptomonedas. Nuestro objetivo es seguir a la vanguardia del mercado de derivados de criptomonedas. Prevemos que en el futuro las criptomonedas serán utilizadas por todos y negociadas por millones de personas. Estamos preparados para el futuro, al igual que nuestro sistema。",
            },
        },
        language: {
            tit: "Elegir idioma",
        },
        market: {
            tit: "Configuración del mercado",
            tit1: "Visualización de precios",
            tit2: "Color de subida y caída",
            buy_price: "Precio de compra",
            buy_price_tip: "Dibuja la línea k al precio de compra",
            sell_price: "Precio de venta",
            sell_price_tip: "Dibuja la línea k al precio de venta",
            color1: "El verde sube y el rojo baja",
            color2: "Rojo sube y verde baja",
        },
        notice: {
            tit: "Configuración de notificación",
            tit1: "Abrir canales de notificación",
            p1: "¡Por favor, abra / verifique los siguientes canales de notificación, ¡ no se pierdan noticias importantes!",
            message: "SMS",
            push: "Empujar",
            verify: "Verificación",
            verified: "Verificado",
            email: "Correo electrónico",
            type1: "Categoría de marketing",
            tit2: "Aviso de campaña de marketing",
            desc2: "Información preferencial de la plataforma, actividades operativas, etc.",
            tit3: "Orientación operativa",
            desc3: "Materiales de conocimiento ricos y profesionales",
            tit4: "Recordatorio de información",
            desc4: "Datos financieros recientes e información de noticias",
            tit5: "Recordatorio de fluctuación inteligente",
            desc5: "Contiene fluctuaciones de precios de variedades importantes que le ayudan a aprovechar rápidamente las oportunidades.",
            type2: "Categoría de informe",
            tit6: "Informe de la cuenta",
            desc6: "El informe de su cuenta se enviará mensualmente de la siguiente manera",
            cycle: "Ciclo",
            month: "Mensual",
        },
        protocol: {
            tit: "Declaraciones y acuerdos",
            label1: "Política de privacidad",
            label2: "Declaración de divulgación del producto",
            label3: "Acuerdo con el cliente",
            label4: "Declaración de divulgación de riesgos",
        },
        setting: {
            tit: "Configuración",
            label1: "Configuración de Seguridad",
            label2: "Configuración del mercado",
            label3: "Configuración de la transacción",
            label4: "Idiomas",
            label5: "Modo temático",
            label6: "Configuración de notificación",
            label7: "Guía de transacciones",
            label8: "Declaraciones y acuerdos",
            label9: "Limpiar la caché",
            label10: "Número de versión",
            logout: "Salir de inicio de sesión",
        },
        theme: {
            tit: "Modo temático",
            theme1: "Modo claro",
            theme2: "Modo oscuro",
        },
        trade: {
            tit: "Configuración de la transacción",
            onclick_close: "Cierre de posiciones con un solo clic",
        }
    },
    trade: {
        fliter_type1: "Opcional",
        fliter_type2: "Mercado",
        place_search: "Introduzca el nombre de la variedad / búsqueda de código",
        fliter_type3: "Popular",
        fliter_type4: "Divisas",
        fliter_type5: "Productos Básicos",
        fliter_type6: "Acciones",
        fliter_type7: "Índice",
        fliter_type8: "Cifrado",
        tip1: "Completar la autenticación puede ingresar a la transacción de oro e invertir en todo el mundo",
        sell: "Vender",
        buy: "Comprar",
        add_optional: "Añadir opcional",
        optional_recommend: "Recomendación opcional",
        change_batch: "Cambiar un lote",
        edit_tit: "Edición opcional",
        breed: "Variedad",
        topping: "En la parte superior",
        sort: "Ordenar",
        all: "Selección completa",
    },
    table: {
        label_breed: "Variedad",
        label_direction: "Dirección",
        label_price: "Precio",
        label_operate: "Operación",
        empty_tip1: "No hay recordatorio por el momento",
        empty_tip2: "No hay registro de entrada",
        empty_tip3: "No hay registro de oro",
        label_amount: "Importe",
        label_type: "Tipo",
        label_time: "Tiempo",
        label_name: "Nombre",
        label_email: "Correo electrónico",
        label_address: "País de residencia",
        label_account: "Cuenta de transacción",
        label_currency: "Moneda básica",
        label_company: "Empresa que abre una cuenta ahora",
    },
    form: {
        to: "A",
        start_time: "Hora de inicio",
        end_time: "Fin del tiempo",
        week: "Casi una semana",
        month: "Casi un mes",
        threeMonth: "Casi tres meses",
        sixMonth: "Casi medio año",
        label_feedback: "Preguntas y sugerencias",
        place_feedback: "Introduzca su pregunta o sugerencia",
        label_img: "Imagen",
        label_img_tip: "Selección y llenado, proporcionando capturas de pantalla de preguntas",
        feedback_tip: "Si tiene una pregunta urgente, Póngase en contacto",
        online: "Servicio al cliente en línea",
        label_cardNumber: "Número de documento",
        label_realName: "Nombre completo",
        place_realName: "Introduzca su nombre",
        message_realName: "Por favor, introduzca el nombre completo",
        label_firstName: "Apellidos",
        place_firstName: "Introduzca su apellido",
        message_firstName: "Por favor, introduzca el apellido",
        label_lastName: "Nombre",
        place_lastName: "Introduzca su segundo nombre",
        message_lastName: "Por favor, introduzca el nombre",
        label_birthday: "Fecha de nacimiento",
        place_birthday: "Introduzca su fecha de nacimiento",
        message_birthday: "Por favor, introduzca la fecha de nacimiento",
        label_idType: "Tipo de documento",
        tip_cardMain: "Tomar o subir una foto de la parte delantera de su tarjeta de identificación",
        tip_cardBack: "Tomar o subir una foto en la parte posterior de su tarjeta de identificación",
        tip_cardMain1: "Tomar o subir una foto de la parte delantera de la licencia de conducir",
        tip_cardBack1: "Tomar o subir fotos detrás de la licencia de conducir",
        tip_cardMain2: "Tomar o subir fotos del pasaporte",
        tip_cardBack2: "Tomar o subir fotos del pasaporte",
        label_password: "Contraseña",
        place_password: "Por favor, introduzca la contraseña",
        message_password: "Por favor, introduzca la contraseña",
        label_crmpsd: "Confirmar contraseña",
        place_crmpsd: "Introduzca la contraseña de nuevo",
        message_crmpsd: "Introduzca la contraseña de nuevo",
        label_email: "Correo electrónico",
		place_email: "Dirección de correo electrónico",
		message_email: "Por favor, introduzca el buzón",
        label_captcha: "Código de verificación",
		place_captcha: "Por favor, introduzca el Código de verificación",
		message_captcha: "Por favor, introduzca el Código de verificación",
		get_captcha: "Obtener el Código de verificación",
        label_phone: "Teléfono",
		place_phone: "Por favor, introduzca el teléfono",
		message_phone: "Por favor, introduzca el teléfono",
        labelAmount: "Importe",
        placeAmount: "Por favor, introduzca la cantidad",
        messageAmount: "Por favor, introduzca la cantidad",
        labelWalletName: "Nombre de la billetera",
		messageWalletName: "Por favor, introduzca el nombre de la billetera.",
		placeWalletName: "Por favor, introduzca el nombre de la billetera.",
		labelWalletAddress: "Dirección de la billetera",
		messageWalletAddress: "Por favor, introduzca la dirección de la billetera.",
		placeWalletAddress: "Por favor, introduzca la dirección de la billetera.",
        labelWithdrawAccount: "Cuenta de retiro de efectivo",
        placeWithdrawAccount: "Cuenta de retiro de efectivo",
        messageWithdrawAccount: "Cuenta de retiro de efectivo",
		placeTimeLimit: "Por favor, introduzca el límite de tiempo",
		messageTimeLimit: "Por favor, introduzca el límite de tiempo",
    },
	city: {
        albania: "Albania",
        algeria: "Argelia",
	    argentina: "Argentina",
	    armenia:  "Armenia",
	    australia: "Australia",
	    pakistan: "Pakistán",
        austria:  "Austria",
	    bahrain: "Bahrein",
	    belgium: "Bélgica",
	    bosnia_and_Herzegovina: "Bosnia y Herzegovina",
	    brazil: "Brasil",
	    brunei: "Brunei",
	    bulgaria: "Bulgaria",
	    cambodia: "Camboya",
		canada: "Canadá",
		cameroon: "Camerún",
		chile: "Chile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croacia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estonia",
		ethiopia: "Etiopía",
        finland : "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Alemania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong kong, China",
		hungary: "Hungría",
		iceland: "Islandia",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Irán",
		iraq: "Irak",
		japan: "Japón",
		kazakstan: "Kazajstán",
		kenya: "Kenia",
		korea: "Corea del Sur",
		kuwait: "Kuwait",
		kyrgyzstan:"Kirguistán",
		laos:"Laos",
		latvia:"Letonia",
		lithuania:"Lituania",
		luxembourg:"Luxemburgo",
		macao_China:"Macao, China",
		macedonia:"Macedonia",
		malaysia:"Malasia",
		malta:"Malta",
		mexico:"México",
		moldova:"Moldavia",
		monaco:"Mónaco",
		mongolia:"Mongolia",
		montenegro:"República de Montenegro",
		morocco:"Marruecos",
		myanmar:"Myanmar",
		netherlands:"Países Bajos",
		new_Zealand:"Nueva Zelanda",
		nepal:"Nepal",
		nigeria:"Nigeria",
		norway:"Noruega",
		oman:"Omán",
		palestine:"Palestina",
		panama:"Panamá",
		paraguay:"Paraguay",
		peru:"Perú",
		philippines:"Filipinas",
		poland:"Polonia",
		portugal:"Portugal",
		puerto_Rico:"Puerto Rico",
		qatar:"Qatar",
		romania:"Rumanía",
		russia:"Rusia",
		republic_of_Trinidad_and_Tobago:"República de Trinidad y Tobago",
		rwanda:"Rwanda",
		saudi_Arabia:"Arabia Saudita",
		serbia:"Serbia",
		singapore:"Singapur",
		slovakia:"Eslovaquia",
		slovenia:"Eslovenia",
		south_Africa:"Sudáfrica",
		spain:"España",
		sri_Lanka:"Sri Lanka",
		sweden:"Suecia",
		switzerland:"Suiza",
		taiwan_China:"Taiwán, China",
		tajikistan:"Tayikistán",
		tanzania:"Tanzania",
		thailand:"Tailandia",
		turkey:"Turquía",
		turkmenistan:"Turkmenistán",
		ukraine:"Ucrania",
		united_Arab_Emirates:"Emiratos Árabes Unidos",
		united_Kingdom:"Reino Unido",
		united_States:"Estados Unidos",
		uzbekistan:"Uzbekistán",
		venezuela:"Venezuela",
		vietnam:"Vietnam",
		afghanistan:"Afganistán",
		angola:"Angola",
		azerbaijan:"Azerbaiyán",
		bangladesh:"Bangladesh",
		belarus:"Bielorrusia",
		belize:"Belice",
		benin:"Benin",
		bhutan:"Bhután",
		bolivia:"Bolivia",
		botswana:"Botswana",
		british_Virgin_Islands:"Islas Vírgenes Británicas",
		burkina_Faso:"Burkina Faso",
		burundi:"Burundi",
		cape_Verde:"Cabo Verde",
		cayman_Islands:"Islas Caimán",
		central_African_Republic:"República Centroafricana",
		chad:"Chad",
		comoros:"Comoras",
		the_Republic_of_the_Congo:"Congo (brazzaville)",
		democratic_Republic_of_the_Congo:"República Democrática del Congo (rdc)",
		djibouti:"Djibouti",
		ecuador:"Ecuador",
		el_Salvador:"El Salvador",
		equatorial_Guinea:"Guinea Ecuatorial",
		eritrea:"Eritrea",
		fiji:"Fiji",
		gabon:"Gabón",
		gambia:"Gambia",
		greenland:"Groenlandia",
		guatemala:"Guatemala",
		guinea:"Guinea",
		haiti:"Haití",
		isle_of_Man:"Isla de Man",
		cote_d_Ivoire:"Costa de Marfil",
		jamaica:"Jamaica",
		jordan:"Jordania",
		lebanon:"Líbano",
		lesotho:"Lesotho",
		liberia:"Liberia",
		libya:"Libia",
		madagascar:"Madagascar",
		malawi:"Malawi",
		maldives:"Maldivas",
		mali:"Malí",
		mauritania:"Mauritania",
		mauritius:"Mauricio",
		mozambique:"Mozambique",
		namibia:"Namibia",
		nicaragua:"Nicaragua",
		republic_of_Niger:"Níger",
		north_Korea:"Corea del Norte",
		reunion:"Reunión",
		san_Marino:"San Marino",
		senegal:"Senegal",
		sierra_Leone:"Sierra Leona",
		somalia:"Somalia",
		sudan:"Sudán",
		suriname:"Surinam",
		eswatini:"Swazilandia",
		syria:"Siria",
		togo:"Togo",
		tonga:"Tonga",
		tunisia:"Túnez",
		united_States_Virgin_Islands:"Islas Vírgenes de los Estados Unidos",
		uganda:"Uganda",
		uruguay:"Uruguay",
		vatican:"Vaticano",
		yemen:"Yemen",
		yugoslavia:"Yugoslavia",
		zambia:"Zambia",
		zimbabwe:"Zimbabwe",
		china:"China",
	}
}
