export default {
    common: {
        expected: "预期收益",
        view: "查看",
        confirm: "確定",
        cancel: "取消",
        deposit: "入金",
        withdraw: "出金",
        noData: "暫無數據",
        submit: "提交",
        modify: "修改",
        hand: "手",
        confirm_verify: "確認驗證",
        next: "下一步",
        complete: "完成",
        delete: "删除",
        countryCode: "國家/地區代碼",
        no_balance: "帳戶餘額不足",
    },
	currency: {
		btcusdt: "比特幣",
		ethusdt: "以太坊",
		ltcusdt: "萊特幣",
		xrpusdt: "瑞波幣",
		adausdt: "艾達幣",
		eosusdt: "柚子幣",
		dotusdt: "波卡幣",
		trxusdt: "波場幣",
		xmrusdt: "門羅幣",
		dogeusdt: "狗狗幣",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "澳元美元",
		fx_sgbpusd: "英鎊美元",
		fx_seurusd: "歐元美元",
		fx_snzdusd: "新西蘭元（紐元）美元",
		fx_susdcad: "美元加元",
		fx_susdchf: "美元瑞郎",
		fx_susdjpy: "美元日元",
		fx_saudcad: "澳元加元",
		fx_saudchf: "澳元瑞郎",
		fx_saudjpy: "澳元日元",
		fx_saudnzd: "澳元新西蘭元（紐元）",
		fx_scadchf: "加元瑞郎",
		fx_scadjpy: "加元日元",
		fx_schfjpy: "瑞郎日元",
		fx_seuraud: "歐元澳元",
		fx_seurcad: "歐元加元",
		fx_seurgbp: "歐元英鎊",
		fx_seurjpy: "歐元日元",
		fx_seurnzd: "歐元新西蘭元（紐元）",
		fx_sgbpaud: "英鎊澳元",
		fx_sgbpchf: "英鎊瑞郎",
		fx_sgbpjpy: "英鎊日元",
		fx_sgbpnzd: "英鎊新西蘭元（紐元）",
		fx_scadnzd: "加元紐幣",
		hf_CL: "WTI紐約原油",
		hf_OIL: "布倫特原油",
		hf_GC: "紐約黃金",
		hf_SI: "紐約白銀",
		hf_HG: "美銅",
		hf_NG: "美國瓦斯",
		hf_CAD: "倫敦銅",
		hf_AHD: "倫敦鋁",
		HX_AAPL: "蘋果",
		HX_BABA: "阿裡巴巴",
		HX_GOOG: "穀歌",
		HX_WMT: "沃爾瑪",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "特斯拉",
		HX_N225: "日經225",
		HX_GDAXI: "德國DAX30",
		HX_IBEX: "西班牙IBEX35",
		HX_SPX: "標普500指數",
		HX_FTSE: "英國富時100",
		HX_KS11: "韓國KOSPI",
		HX_NZ50: "紐西蘭50",
		HX_PSI20: "葡萄牙PSI20",
		hf_ZSD: "倫敦鋅",
		hf_NID: "倫敦鎳",
		hf_PBD: "倫敦鉛",
		hf_SND: "倫敦錫",
		hf_XAU: "倫敦金（現貨黃金）",
		hf_XAG: "倫敦銀（現貨白銀）",
		hf_XPT: "鉑金期貨",
		hf_XPD: "鈀金期貨",
		hf_CT: "美國棉花",
		hf_SM: "美黃豆粉",
		hf_BO: "美黃豆油",
		HX_SENSEX: "印度孟買SENSEX",
		HX_KLSE: "富時馬來西亞KLCI",
		HX_KSE100: "巴基斯坦卡拉奇",
		HX_FCHI: "法國CAC40",
		HX_SX5E: "歐洲斯諾克50",
		HX_ICEXI: "冰島ICEX",
		HX_ASE: "希臘雅典ASE",
		HX_OMXC20: "OMX哥本哈根20",
		HX_OSEBX: "挪威OSEBX",
		HX_OMXSPI: "瑞典OMXSPI",
		HX_AMD: "超微電晶體",
		HX_DIS: "迪士尼",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "萬事達",
		HX_NVDA: "英偉達",
		HX_INTC: "英特爾",
		HX_GOGO: "Gogo Inc",
		HX_STX: "希捷科技",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "阿童木幣",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "請選擇貨幣",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: '美元',
        currenctType15: '港幣',
        currenctType16: '台幣',
        currenctType17: '馬幣',
        currenctType18: '新幣',
        currenctType19: '日元',
        currenctType20: '歐元',
        currenctType21: '澳元',
        currenctType22: '印尼盾',
        currenctType23: '英鎊',
        currenctType24: '泰銖',
        currenctType25: '加元',
        currenctType26: '越南盾',
        currenctType27: '韓幣',
        currenctType28: '澳門幣',
    },
    addnew3: {
        direction: "方向",
        sell_price: "卖出價",
        number: "數量",
        type: "交割時間",
        buy_price: "買入價",
        profit: "盈虧",
        sell_time: "賣出時間",
    },
    addnew2: {
        address: {
            account_type: "帳戶類型",
            ifsc_code: "IFSC程式碼",
            title: "取款地址",
            account_number: "帳戶號碼",
            add_btn: "添加地址",
            label_type: "選擇類型",
            place_type: "請選擇類型",
            label_number: "帳戶號碼",
            place_number: "請輸入帳戶號碼",
            wallet_address: '錢包地址',
            bank_name: '銀行名字',
            bank_address: "銀行地址",
            name: "姓名",
            payee_address: '收款人地址',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "分行名字",
            branch_code: "分行程式碼",
            bank_code: "銀行程式碼",
            phone: "手機號",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "請輸入內容",
        },
    },
    addnew: {
        realFirst: "請先進行身份認證",
        payFirst: "请先设置提款密码",
        password1: "修改登入密碼",
        password2: "修改提款密碼",
        paypassTit1: "請設定您的提款密碼",
        oldPass: "舊密碼",
        allView: "全部已读",
        confirmOrder: "確認訂單",
        name: "名字",
        fee: "手續費",
        orderCycle: "選擇時間",
        loan: "貸款",
        loanTit: "助力貸款",
        loanHostory: "貸款記錄",
        labelProductTitle: "產品名字",
        immediateRepayment: "立即還款",
        loadAmount: "預期貸款金額",
        RepaymentCycle: "貸款償還週期",
        day: "天",
        dailyRate: "日利率",
        repaymentMethod: "還款方式",
        lendingCompany: "貸款公司",
        loanTip: "信用貸款（請確保圖片清晰可見）",
        loanTip1: "上傳房產證書",
        loanTip2: "收入證明（雇傭關係）",
        loanTip3: "銀行對帳單詳細資訊",
        loanTip4: "上傳身份證正面照片",
        interest: "利益",
        repaid: "已還款",
        unpaid: "未還款",
        tab1: "幣幣",
        tab2: "合約",
        tab3: "期權",
        available: "可用額度",
        pending: "鎖定",
        equivalent: "折合",
        fundTransfer: "資金轉移",
        flashExchange: "閃兌",
        select_breed: "選擇幣種",
        from: "從",
        to: "至",
        transferQuantity: "轉移數量",
        repaymentMethod1: "到期一次還款"
    },
    params: {
        product_foreign: "外匯",
        product_shop: "商品",
        product_number: "指數",
        product_stock: "股票",
        product_coin: "加密",
        billTypes: [
            { v: 101, name: '充值' },
            { v: 102, name: '添加' },
            { v: 103, name: '凍結' },
            { v: 104, name: '解凍' },
            { v: 105, name: '存入' },
            { v: 106, name: '存入' },
            { v: 201, name: '提現凍結' },
            { v: 202, name: '扣除' },
            { v: 203, name: '提現成功' },
            { v: 204, name: '提現失敗' },
            { v: 205, name: '提現手續費費' },
            { v: 206, name: '轉出' },
            { v: 207, name: '轉入' },
            { v: 208, name: '幣幣轉出' },
            { v: 209, name: '幣幣轉入' },
            { v: 301, name: '保證金扣除' },
            { v: 302, name: '手續費扣除' },
            { v: 303, name: '保證金返還' },
            { v: 304, name: '盈利' },
            { v: 305, name: '虧損' },
            { v: 311, name: '購買期權' },
            { v: 312, name: '期權收益' },
            { v: 313, name: '期權退回' },
            { v: 314, name: '期權手續費' },
            { v: 315, name: '幣幣買入凍結' },
            { v: 316, name: '幣幣買入扣除' },
            { v: 317, name: '幣幣買入返回' },
            { v: 318, name: '幣幣買入到賬' },
            { v: 319, name: '幣幣買入到賬' },
            { v: 320, name: '幣幣賣出凍結' },
            { v: 321, name: '幣幣賣出扣除' },
            { v: 322, name: '幣幣賣出返回' },
            { v: 323, name: '幣幣賣出到賬' },
            { v: 324, name: '幣幣賣出到賬' },
            { v: 325, name: '幣幣手續費' },
            { v: 401, name: '礦機加入' },
            { v: 402, name: '礦機返回' },
            { v: 403, name: '礦機加入' },
            { v: 404, name: '礦機退出' },
            { v: 405, name: '礦機退出手續費' },
            { v: 411, name: '借款收入' },
            { v: 412, name: '借款手續費' },
            { v: 413, name: '還款成功' },
        ],
        transType0: "現貨",
        transType1: "合約",
        directionType0: "買入",
        directionType1: "賣出",
        lockStateType0: "運行中",
        lockStateType1: "已結束",
        lockStateType2: "已取消",
    },
    lockming: {
        profit: "近日(日收益)",
        cycle: "理财周期",
        limit: "单笔限额",
        dividend_time: "派息时间",
        every_day: "每日",
        funds: "托管资金",
        back: "到期返回",
        ransom: "提前赎回",
        label_amount: "质押金额",
        place_amount: "请输入...",
        available: "可用",
        expect_income: "预计收益",
        title: "锁仓挖矿",
        tip: "挖矿赚不停",
        label_today_income: "预计今日收益",
        label_total_income: "累计收益",
        label_order: "托管的订单",
        label_number: "单笔数额",
        daily_return: "日收益率",
        subscribe: "申购",
        position: "持仓",
        label_state: "状态",
        subscribe_time: "申购时间",
        expiration_time: "到期时间",
        income: "收益",
        errorTip1: "请输入金额",
        tip_tit: "确定要提前退出吗",
    },
    footer: {
        nav1: "首頁",
        nav2: "交易",
        nav3: "倉位",
        nav4: "我的",
        kline_set1: "行情設定",
        kline_set2: "交易設定",
    },
    login: {
        login: "登入",
        register: "注册",
        type1: "手機號碼",
        type2: "郵箱",
        login_btn: "安全登入",
        register_btn: "立即注册",
        psd_login: "密碼登入",
        verify_login: "驗證碼登入",
        forget: "忘記密碼？",
        no_account: "沒有帳戶？",
        have_account: "已有帳戶",

    },
    home: {
        home: {
            nav1: "漲跌排行",
            nav2: "快速入門",
            nav3: "關於我們",
            nav4: "邀請獎勵",
            most_follow: "最多關注",
            news_type1: "策略",
            news_type2: "新聞",
            news_type3: "財經行事曆",
            news_tab1: "全部",
            news_tab2: "外匯",
            news_tab3: "股票",
            news_tab4: "商品",
            news_tab5: "指數",
            news_tab6: "數據",
            news_tab7: "事件",
            news_tab8: "假期",
            label_previous: "前值",
            label_predictive: "預測值",
            label_announced: "公佈值",
        },
        kilne: {
            label_bp: "昨收",
            label_sp: "今開",
            label_height: "最高",
            label_low: "最低",
            tip: "Hi,我是RichardGPT品種助手",
            row1_tit: "策略",
            row1_rinei: "日內",
            row1_short: "短期",
            row1_middle: "中期",
            row1_time: "發佈時間",
            row1_tit2: "交易策略",
            data_sources: "數據來源",
            row2_tit1: "數據",
            row2_tit2: "交易情緒",
            row2_label_sell: "賣家",
            row2_label_buy: "買家",
            tip2: "僅供參考,不作為本司立場",
            tip3: "每15分鐘更新",
            row2_tit3: "價格漲跌幅",
            minute: "分鐘",
            today: "今天",
            right_tit7: "價格變化區間",
            now_price: "當前價格",
            right_low_price: "最低價",
            right_height_price: "最高價",
            details: "詳情",
            right_label1: "單筆交易數量",
            right_label2: "最大杠杆",
            right_label3: "合約大小",
            right_label4: "最大總持倉數量",
            right_label5: "浮動價差",
            right_label6: "隔夜費",
            hand: "手",
            right_sell: "賣出",
            right_buy: "買入",
            right_collection_time: "收取時間",
            right_tip3: "從整個交易規模中收取",
            right_label9: "交易時段",
            right_label10: "當前時段",
            right_label11: "下一時段",
        },
        placeorder: {
            direction_buyPrice: "買入價",
            direction_sellPrice: "賣出價",
            direction_sell: "賣出",
            direction_buy: "買入",
            market_price: "市價單",
            check_price: "掛單",
            sell_tip: "當價格滿足條件時賣出",
            buy_tip: "當價格滿足條件時買入",
            trade_number: "交易數量",
            hand: "手",
            select_lever: "選擇杠杆",
            right_tip: "需付保證金",
            balance: "可用餘額",
            label_stop_win_price: "止盈",
            label_stop_lose_price: "止損",
            expected_profit_loss: "預計盈虧",
            trailing_stop: "追跡止損",
        },
        ranking: {
            tit: "當日漲跌排行",
            p: "捕捉熱點,抓住機會",
            renew: "更新於",
            growth_chart: "漲幅榜",
            drop_list: "跌幅榜",
            show_closed_market: "展示休市品牌",
            filter1: "全部",
            filter2: "外匯",
            filter3: "商品",
            filter4: "股票",
            filter5: "指數",
            filter6: "加密",
            td1: "排名",
            td2: "趨勢",
            td3: "當日漲幅/賣出價",
        },
        remind: {
            now_sell_price: "當前賣出價",
            direction_buyPrice: "買入價",
            direction_sellPrice: "賣出價",
            direction_sell: "賣出",
            direction_buy: "買入",
            equal: "當",
            p: "達到以下價格時提醒我",
            btn1: "設定提醒",
            list_tit: "提醒清單",
        },
    },
    my: {
        report: {
            tit: "帳戶報告",
            tab1: "報告",
            tab2: "日結單",
            tab3: "月結單",
            history_tit: "交易歷史報告",
            account_tit: "帳戶報告",
            filter: "篩選",
            tip1: "請選擇合適的日期範圍以匯出您的交易歷史報告。",
            tip2: "自定義時間",
            email_send: "發送至郵箱",
        },
        accountMsg: {
            tit: "帳戶資訊",

            label1: "個人資訊",
        },
        bill: {
            tit: "資金流水明細",
            all: "全部",
            time: "時間",
        },
        feedback: {
            tit: "功能建議",
        },
        identity: {
            tit: "身份驗證",
            type1: "身份證",
            type2: "駕照",
            type3: "護照",
            tip: "我確認所提供的所有資訊和檔案都是完整、真實和準確的",
        },
        my: {
            is: "已",
            no: "未驗證",
            verify: "已驗證",
            id_verify: "身份驗證",
            verify_tip: "完成身份驗證即可入金交易",
            no_verify: "立即驗證",
            balance: "可用餘額",
            profit_loss: "盈虧",
            marginLevel: "保證金水准",
            btn1: "切換到真實帳戶",
            deposit_withdraw_record: "出入金記錄",
            capital_flow_details: "資金流水明細",
            welfare_center: "福利中心",
            tip1: "您有",
            tip2: "筆獎勵待領取",
            feedback: "功能建議",
            report: "帳戶報告",
            setting: "設置",
        },
        news: {
            tit: "通知",
        },
        record: {
            tit: "出入金記錄",
            cycle: "週期",
        },
        share: {
            tit: "邀請好友",
            invite_success: "成功邀請好友",
            tip1: "現金獎勵",
            tip2: "邀請好友最高拿",
            tip3: "邀請好友領現金",
        },
        welfare: {
            tit: "福利中心",
            tab1: "卡卷",
            tab2: "活動",
            history_record: "歷史記錄",
            period_validity: "有效期",
        },
        wallet: {
            tit: "錢包",
            addNew: "添加新錢包",
        },
        withdraw: {
            tit: "出金",
            addNew: "添加新錢包",
        },
    },
    order: {
        demo_account: "類比帳戶",
        real_account: "真實帳戶",
        tip: "開通後可入金交易",
        tab1: "持倉",
        tab2: "掛單",
        tab3: "歷史",
        list_empty: "當前無掛單",
        start_trade: "開始交易",
        hand_tip: "可輸入範圍",
        kline: {
            direction_sell: "賣出",
            direction_buy: "買入",
            open_price: "開倉價格",
            now_price: "當前價格",
            trade_account: "交易帳戶",
            demo_account: "類比帳戶",
            order_id: "訂單ID",
            open_time: "開倉時間",
            stop_profit: "止盈",
            stop_loss: "止損",
            trailing_stop: "追跡止損",
            float_profit_loss: "浮動盈虧",
            overnight_fee: "隔夜費",
            margin: "保證金",
            maintainsMargin: "維持保證金",
            open_value: "開倉價值",
            now_value: "當前價值",
            notset: "未設定",
            close_position: "平倉",
            close_number: "平常數量",
            onclick_close: "一鍵平倉",
            btn: "確認平倉",
            balance: "可用餘額",
            profit_loss: "盈虧",
            netValue: "淨值",
            marginLevel: "保證金水准",
        },
        modify: {
            tit: "修改持倉單",
            expected_profit_loss: "預計盈虧",
        },

    },
    setting: {
        security: {
            modify_password_tit: "設定新密碼",
            verify_email_tit: "驗證郵箱",
            verify_password_tit: "驗證您的密碼",
            verify_password_tip: "輸入您的Richard登入密碼以繼續",
            verify_phone_tit: "驗證手機號",
            tit: "帳戶安全",
            tip: "為保護您帳號的安全以及接受我們的服務通知,建議至少開啟一種身份驗證。",
            label1: "修改密碼",
            label2: "註銷帳戶",
            label3: "雙重驗證",
            label4: "設備管理",
            tip2: "新設備登入時需要驗證",
        },
        tradenews: {
            tit: "交易指南",
            tab1: "快速入門",
            tab2: "進階學習",
            tab3: "幫助中心",
            tab4: "關於Richard",
            viewMore: "查看更多",
            details: {
                tit: "外匯簡介",
                tip1: "預估學習時長",
                tip2: "分鐘",
            },
            tab1Row: {
                tit: "這是一個快速學會用Richard交易的課堂",
                tit1: "在Richard上是如何交易的？",
                tit2: "如何開立真實帳戶？",
                tit3: "如何開倉交易？",
                tit4: "如何設定止盈止損？",
                tit5: "如何平倉？",
                p1: "您可以通過買入或賣出來交易該標的。 在Richard開啟倉位，這意味這您對該",
                p2: "標的產品的價格波動進行買賣",
                p3: "例如，您預期黃金價格會上漲，則買入該產品； 反之，您預測黃金價格將會下降，則賣出該產品。 若未來符合預期，您可",
                p4: "從中獲利",
                p5: "此外，Richard提供“杠杆交易”。 您可利用杠杆，使用相同的資金交易更多手數的標的。",
                p6: "杠杆會放大盈利，亦會放大虧損",
                p7: "這也被稱為“保證金交易”。 當您開倉時，您的Richard帳戶將會扣除一定餘額作為",
                p8: "初始保證金",
                p9: "選中產品和買賣方向",
                p10: "交易",
                p11: "點擊'買入/'賣出完成下單",
                p12: "設定“止盈”或“止損”可幫助您",
                p13: "及時鎖住利潤和限制虧損",
                p14: "您可以在開倉成功頁點擊",
                p15: "止盈/止損",
                p16: "進行設定，也可以之後點擊未平合單的”修改”進行止盈止損的",
                p17: "設定或取消",
                p18: "您可以在'已平倉'中查看盈虧",
            },
            tab3Row: {
                h1: "歡迎來到Richard",
                h2: "幫助中心",
                tit0: "所有問題",
                tit1: "類比帳戶",
                tit2: "開立帳戶",
                tit3: "入金與出金",
                tit4: "平臺設定",
                tit5: "交易操作",
                tit6: "費用及收費",
                tit7: "資金安全與法規",
                tit1Tit1: "Richard是否有提供類比帳戶",
                tit1Cont1: "Richard提供類比帳戶供客戶操作使用，您可以在無任何風險的情况下使用我們的網絡平臺和移動應用程式進行交易及熟悉平臺操作</p>",
                tit1Tit2: "如何創建類比帳戶?",
                tit1Cont2: "<p>您只需要點擊此處填上您的電子郵箱地址或手機號碼，並設定您的個人密碼，即可完成注册獲得一個類比帳戶。</p>",
                tit1Tit3: "類比帳戶有什麼用處?",
                tit1Cont3: "<p>類比帳戶跟真實帳戶在交易介面、數據、操作上絕大部分都是一樣的，類比帳戶裏有5萬美元虛擬資金，目的是讓客戶在沒有資金風險的情况下通過類比操作熟悉平臺的各項功能。</p>",
                tit1Tit4: "如何向類比帳戶注資?",
                tit1Cont4: "<p>您並不需要向類比帳戶注資，當您成功注册類比帳戶後，帳戶內會有5萬美金USD或澳元AUD的欖圳資全</p><p>而當類比帳戶內淨值低於200美金USD或澳元AUD時，系統將會自動將您的帳戶資金補回至5萬美金USD或澳元AUD</p>",
                tit1Tit5: "類比帳戶是否有使用期限?",
                tit1Cont5: "<p>類比帳戶有效期為90天，90天后如您未有開立真實帳戶，類比帳戶將會被凍結而且不能進行交易，並在您開立真實帳戶時解凍。</ p><p>而如您在90天內開立真實帳戶，類比帳戶將長期有效。 即使開通真實帳戶後，也可繼續在類比帳戶上進行操作。</p>",
                tit2Tit1: "Richard 提供多少種帳戶類型?  ",
                tit2Cont1: "<p>我們只為個人提供標準及專業交易帳戶，不支持開立公司帳戶和聯名帳戶</p>",
                tit2Tit2: "我可以開立哪種貨幣的交易帳戶?",
                tit2Cont3: "<p>當你開立帳戶時系統會根據您所在地區自動確定您的結算貨幣且將不可修改</p>",
                tit2Tit4: "如何開立真實帳戶?",
                tit2Cont4: "<p>開立真實帳戶請依照以下步驟進行操作</p><p>1. 進入Richard注册頁面，依據提示：登記郵箱手機號碼、設定登入密碼、提交確認後自動創建</p><p></p><p></p>",
                tit3Tit1: "我可以通過什麼管道進行入金?",
                tit3Cont1: "<p>Richard 提供不同的入金管道供客戶選擇，包括且可能不止以下幾項：</p><p>1.Visa/Mastercard -一般是即時到賬</p><p>2.網銀支付-一般是即時到賬</p><p>3.電子錢包（比如：Skrill、Momo、Zalo.Touch'n GO以及Boost等）-一般是即時到賬</p><p>4.ATM卡-一般是即時到賬</p><p>5.國際電匯-一般是3-5天到賬</p>",
                tit3Tit2: "我可以利用協力廠商名下的銀行帳戶/卡入金或出金嗎",
                tit3Cont2: "<p>Richard不接受協力廠商入金。 請您使用您本人名下的個人銀行帳戶/卡進行入金或出金。 若您使用他人名下或公司銀行帳戶/卡入金，資金將會被退回</p>",
                tit3Tit3: "如何從Richard帳戶中申請出金？  ",
                tit3Cont3: "<p>您可在登入網站平臺後，點擊出金]，選取銀行帳戶或Visa/Mastercard並填寫出金金額，然後按[提交]，在確定資料正確後，Richard將於1-2個工作日內處理您的申請</p>",
                tit4Tit1: "Richard提供多少倍杠杆交易？",
                tit4Cont1: "<p>Richard平臺提供的杠杆倍數是根據監管當局的規定製定的，不同的產品其最高杠杆亦有不同詳情請登入交易平臺進行查看。</p>",
                tit4Tit2: "如何變更我的密碼?",
                tit4Cont2: "<p>您可在登入平臺後，選擇[更多]-- [安全設置]--T修改密碼]重新設定新密碼</p>",
                tit4Tit3: "如何登入Richard交易平臺?",
                tit4Cont3: "<p>App:前往“我的”並點擊左上方“登入l注册”進入登入頁。</ p>Web:點擊Richard官方網站右上角“登入”進入登入頁。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "平臺提醒有什麼管道?",
                tit4Cont4: "<p>Richard 通過電郵、簡訊、平臺推送進行提醒。</p>",
                tit5Tit1: "Richard 提供怎樣的價差？?",
                tit5Cont1: "<p>我們收取低價差，且不收任何傭金。 而價差為浮動的，實際收取的價差將會視您所交易的品種而定，具體依交易平臺即時顯示為准。</p>",
                tit5Tit2: "如何建立市價單?",
                tit5Cont2: "<p>點擊[交易]（Trade）會顯示平臺可提供交易的所有產品，您可在右側輸入框中輸入品種程式碼或名稱進行蒐索，選擇一種產品點擊[買入/賣出]SELL/BUY）會彈出交易視窗，在交易視窗中您可看到當前價格和預計所需保證金。 您可手動調節開倉交易數量，設定止盈止損來進行風險控</p>",
                tit5Tit3: "如何建立掛單?",
                tit5Cont3: "<p>在[交易]（Trade）選擇一種產品點擊[買入/賣出]（SELL/BUY）會彈出交易視窗，在交易視窗</p>",
                tit5Tit4: "如何平倉?",
                tit5Cont4: "<p>若要進行平倉，在r倉位]（Position）內點擊查看平臺上的[持倉]（Open），再點選您希望進行平倉的頭寸，然後在右方點擊[平倉l（Close）鍵。</p>",
                tit5Tit5: "如何修改或删除掛單?",
                tit5Cont5: "<p>若要修改或删除掛單，在[倉位]（Position）內點擊查看平臺上的[掛單]（Orders），再點選您希望進行中修改或删除的掛單</p>",
                tit6Tit1: "使用交易平臺是否會被收取手續費?",
                tit6Cont1: "<p>Richard 為完全免傭金交易平臺。 我們收取低價差，根據您的特定交易情况，平臺有可能產生額外費用，例如隔夜利息。</p>",
                tit6Tit2: "入金是否有任何費用?",
                tit6Cont2: "<p>我們不會向客戶收取任何入金相關費用，但您的付款或中轉銀行可能會收取手續費。 我們建議您向相關銀行査詢他們是否向您收取任何費用。</p>",
                tit6Tit3: "出金是否有任何費用?",
                tit6Cont3: "<p>Richard 不會向客戶收取任何出金的相關費用除非您出金的金額低於最低要求或超過當月免費出金的最大次數。 有關更多詳細資訊，請參閱我們的出金政策。</p>",
                tit7Tit1: "我在Richard帳戶中存入的資金是否會被用作其他用途？",
                tit7Cont1: "<p>不會。 零售客戶存款按照監管法規在必要時單獨存放在信託帳戶。 將資金存入客戶款項帳戶或自客戶款項帳戶選取資金或作出付款時，Richard嚴格遵守監管法規中關於客戶款項的規則。</p>",
                tit7Tit2: "若是Richard倒閉或是負債，我的資金是否也受到影響",
                tit7Cont2: "<p>零售客戶存款按照監管法規在必要時單獨存放在信託帳戶。 如果Richard出現倒閉，勒令取消牌照或者任何無法持續經營的情况，客戶資金仍可隔離，並受限於監管法律中客戶資金條例的監管</p>",
                tit7Tit3: "Richard是否符合法律規定？",
                tit7Cont3: "<p>本公司完全符合相關公司法與金融監管法律規定，是經相關監管機构授權並嚴格監管的金融服務商。 本公司按照監管的嚴格要求，為客戶的資金安全提供全面及足够的保障。</p>",
                tit7Tit4: "客戶的個人資料是否安全?",
                tit7Cont4: "<p>Richard嚴格遵守個人資料保障條例的規定，客戶個人資料絕不外泄。 公司會確保雇員遵守法例規定的警衛和保密嚴謹標準，高度關注對客戶數據的保密和使用之安排。 詳情可參閱本公司的法律披露檔案。</p>",
            },
            tab4Row: {
                span: "Q查證方法",
                p1: "Richard專注於外匯交易市場。我們的目標是創建一個高效、公平的市場，將各種背景和交易風格的交易員連接起來。高匹配引擎容量、低延遲、先進的風險管理和高流動性使Richard成為外匯市場上獨一無二的參與者。",
                p2: "Richard最終的目標是創建世界一流以外匯為主的綜合交易所。儘管這是一項極具挑戰性的任務，但在兩年多的時間裏，團隊完成了系統開發工作。2016年6月，Richard正式上線。",
                p3: "Richard 由富有金融交易和金融科技行業經驗與知識的資深團隊組成，創立於澳大利亞墨爾本。 Richard的理念是交易變得更簡便、更友好。",
                p4: "現時，我們的客戶可以交易永續合約、期貨合約和期權合約。Richard發展迅速，是提供外匯、加密貨幣期貨和永久合約交易的頂級綜合交易所之一。此外，Richard仍然是提供現金結算加密貨幣選項的領先交易所，並繼續為行業樹立標準。",
                p5: "Richard Holding獲開曼群島金融管理局（CIMA）授權並受其監管，SIB牌照號碼為1612446。 如欲瞭解更多開曼牌照詳情，可到CIMA官方網站www.cima.ky（",
                p6: "）進行査詢。 RichardGlobal持有澳大利亞證券投資委員會（ASIC）授權的金融服務牌照（AFSL 398528）。 如欲瞭解更多澳大利亞牌照詳情，可到ASIC官方網站www.asic.gov.au（",
                p7: ") 進行査詢。 Richard International獲模裡西斯金融服務委員會（FSC）授權並受其監管，許可證號碼為GB20025791。 如欲瞭解模裡西斯牌照詳情，可到FSC官方網站www.fscmauritius.org (",
                p8: ")進行査詢。 Richard一切業務運營均在嚴格監管下進行及遵從所有規定",
                p9: "的背景",
                p10: "Richard由富有金融交易和金融科技行業經驗與智識的資深團隊組成，創立於澳大利亞墨爾本",
                p11: "Richard的理念是交易變得更簡便、更友好。 我們的整體化戰畧和商業模式使得我們對行業發展和市場需求擁有全域性的深度觀察和認知，從而能够更有針對性的配寘資源，不斷創新科技和優化效率持續為客戶帶來更便捷、更友好的交易體驗",
                p12: "為何選擇",
                p13: "低門檻的交易條件",
                p14: "簡潔直觀交易平臺",
                p15: "有競爭力的交易成本",
                p16: "受行業權威監管",
                p17: "高水準的線上支持",
                p18: "負數餘額保護",
                p19: "獎項及榮譽",
                p20: "Richard非常榮幸獲得World Finance Awards的[最佳多元資產經紀商]、Global BrandsMagazine的[亞太地區最佳移動交易平臺][全球增長最快金融科技經紀商]等多項殊榮，這一切肯定了團隊一直以來的付出與努力。",
                p21: "最佳多元資產經紀商",
                p22: "的運營理念",
                p23: "我們的價值觀",
                p24: "我們是全球性外匯交易所致力於提供專業、透明和安全的外匯交易環境。我們的運營管理團隊擁有豐富的行業經驗和專業知識，確保客戶在交易過程中獲得最佳的交易條件和服務品質。",
                p25: "誠實正直",
                p26: "用戶的信任至關重要；因此，我們通過提供公平的風險管理系統、平等對待所有市場參與者和先進的安全解决方案，以最高標準的誠信開展業務。",
                p27: "服務質量",
                p28: "我們秉持著「穩健、誠信、創新、合作」的價值觀，始終將客戶利益放在首位。我們以客戶需求為導向，不斷優化產品和服務，提升交易體驗和客戶滿意度。同時，我們對客戶資金的安全和隱私保護高度重視，採取多重安全措施保障客戶資產安全。",
                p29: "創新",
                p30: "在快節奏的外匯交易市場中，創新是必須的。Richard是一家以解決方案為驅動的公司，旨在為數千萬用戶提供最佳的交易體驗，圍繞這一目標搭建我們可擴展和高性能系統架構。",
                p31: "為什麼選擇衍生品？",
                p32: "交易衍生品有很多好處—交易費用更低，杠杆率更高，流動性更高，更容易做空。",
                p33: "衍生品交易也允許更多樣化的交易策略。交易員可以使用衍生品來對沖風險、套利或投機交易。",
                p34: "科技",
                p35: "為了確保一個高性能的系統，它在上線前已經開發了兩年多。",
                p36: "開發該平臺的框架是為了確保能够以低延遲處理大量請求。我們專門為Richard開發了一個獨特的匹配引擎，我們所有的科技都是專有的。",
                p37: "自啟動以來，我們的增量清算和風險管理系統確保社會化損失為零。",
                p38: "在Richard，我們相信外匯交易的未來。我們將繼續走在全球金融市場的前列。我們已經為未來做好了準備，我們的系統也是如此。",
            },
        },
        language: {
            tit: "選擇語言",
        },
        market: {
            tit: "行情設定",
            tit1: "價格顯示",
            tit2: "漲跌顏色",
            buy_price: "買入價",
            buy_price_tip: "以買入價繪製K線",
            sell_price: "賣出價",
            sell_price_tip: "以賣出價繪製K線",
            color1: "綠漲紅跌",
            color2: "紅漲綠跌",
        },
        notice: {
            tit: "通知設定",
            tit1: "打開通知通路",
            p1: "請打開/驗證以下通知通路,重要消息不錯過！",
            message: "簡訊",
            push: "推送",
            verify: "驗證",
            verified: "已驗證",
            email: "電子郵箱",
            type1: "行銷類",
            tit2: "行銷活動通知",
            desc2: "平臺的優惠資訊、運營活動等消息",
            tit3: "操作指導",
            desc3: "豐富且專業的知識資料",
            tit4: "資訊提醒",
            desc4: "近期的財經數據和新聞資訊",
            tit5: "智慧波動提醒",
            desc5: "包含重要品種價格波動,有助於您快速把握機會",
            type2: "報告類",
            tit6: "帳戶報告",
            desc6: "您的帳戶報告會以一下管道每月發送",
            cycle: "週期",
            month: "每月",
        },
        protocol: {
            tit: "聲明與協定",
            label1: "隱私政策",
            label2: "產品披露聲明",
            label3: "客戶協定",
            label4: "風險披露聲明",
        },
        setting: {
            tit: "設定",
            label1: "安全設置",
            label2: "行情設定",
            label3: "交易設定",
            label4: "語言",
            label5: "主題模式",
            label6: "通知設定",
            label7: "交易指南",
            label8: "聲明與協定",
            label9: "清理緩存",
            label10: "版本號",
            logout: "登出",
        },
        theme: {
            tit: "主題模式",
            theme1: "淺色模式",
            theme2: "深色模式",
        },
        trade: {
            tit: "交易設定",
            onclick_close: "一鍵平倉",
        }
    },
    trade: {
        fliter_type1: "自選",
        fliter_type2: "市場",
        place_search: "輸入品種名稱/代碼搜索",
        fliter_type3: "熱門",
        fliter_type4: "外匯",
        fliter_type5: "商品",
        fliter_type6: "股票",
        fliter_type7: "指數",
        fliter_type8: "加密",
        tip1: "完成身份驗證即可入金交易,投資全球",
        sell: "賣出",
        buy: "買入",
        add_optional: "添加自選",
        optional_recommend: "自選推薦",
        change_batch: "換一批",
        edit_tit: "編輯自選",
        breed: "品種",
        topping: "置頂",
        sort: "排序",
        all: "全選",
    },
    table: {
        label_breed: "品種",
        label_direction: "方向",
        label_price: "價格",
        label_operate: "操作",
        empty_tip1: "暫無提醒",
        empty_tip2: "沒有入金記錄",
        empty_tip3: "沒有出金記錄",
        label_amount: "金額",
        label_type: "類型",
        label_time: "時間",
        label_name: "姓名",
        label_email: "電子郵箱",
        label_address: "居住國家",
        label_account: "交易帳號",
        label_currency: "基礎貨幣",
        label_company: "現開戶公司",
    },
    form: {
        to: "至",
        start_time: "開始時間",
        end_time: "結束時間",
        week: "近一周",
        month: "近一個月",
        threeMonth: "近三個月",
        sixMonth: "近半年",
        label_feedback: "問題和建議",
        place_feedback: "請輸入您的問題或建議",
        label_img: "圖片",
        label_img_tip: "選填,提供問題截圖",
        feedback_tip: "如果您有緊急問題,請聯系",
        online: "線上客服",
        label_cardNumber: "證件號碼",
        label_realName: "名字",
        place_realName: "請輸入您的名字",
        message_realName: "請輸入全名",
        label_firstName: "姓氏",
        place_firstName: "請輸入您的姓氏",
        message_firstName: "請輸入姓氏",
        label_lastName: "中間名",
        place_lastName: "選填",
        message_lastName: "請輸入名稱",
        label_birthday: "出生日期",
        place_birthday: "請輸入您的出生日期",
        message_birthday: "請輸入出生日期",
        label_idType: "證件類型",
        tip_cardMain: "拍攝或上傳身份證正面的照片",
        tip_cardBack: "拍攝或上傳身份證背面的照片",
        tip_cardMain1: "拍攝或上傳駕駛執照正面的照片",
        tip_cardBack1: "拍攝或上傳駕駛執照背後的照片",
        tip_cardMain2: "拍攝或上傳護照照片",
        tip_cardBack2: "拍攝或上傳護照照片",
        label_password: "密碼",
        place_password: "請輸入密碼",
        message_password: "請輸入密碼",
        label_crmpsd: "確認密碼",
        place_crmpsd: "請再次輸入密碼",
        message_crmpsd: "請再次輸入密碼",
        label_email: "電子郵箱",
        place_email: "電子郵箱地址",
        message_email: "請輸入郵箱",
        label_captcha: "驗證碼",
        place_captcha: "請輸入驗證碼",
        message_captcha: "請輸入驗證碼",
        get_captcha: "獲取驗證碼",
        label_phone: "電話",
        place_phone: "請輸入電話",
        message_phone: "請輸入電話",
        labelAmount: "金額",
        placeAmount: "請輸入金額",
        messageAmount: "請輸入金額",
        labelWalletName: "錢包名字",
        messageWalletName: "請輸入錢包名字",
        placeWalletName: "請輸入錢包名字",
        labelWalletAddress: "錢包地址",
        messageWalletAddress: "請輸入錢包地址",
        placeWalletAddress: "請輸入錢包地址",
        labelWithdrawAccount: "提現帳戶",
        placeWithdrawAccount: "提現帳戶",
        messageWithdrawAccount: "提現帳戶",
        placeTimeLimit: "請輸入時間限制",
        messageTimeLimit: "請輸入時間限制",
    },
    city: {
        albania: "阿爾巴尼亞",
        algeria: "阿爾及利亞",
        argentina: "阿根廷",
        armenia: "亞美尼亞",
        australia: "澳大利亞",
        pakistan: "巴基斯坦",
        austria: "奧地利",
        bahrain: "巴林",
        belgium: "比利時",
        bosnia_and_Herzegovina: "波黑",
        brazil: "巴西",
        brunei: "汶萊",
        bulgaria: "保加利亞",
        cambodia: "高棉",
        canada: "加拿大",
        cameroon: "喀麥隆",
        chile: "智利",
        colombia: "哥倫比亞",
        costa_Rica: "哥斯达黎加",
        croatia: "克羅地亞",
        cyprus: "賽普勒斯",
        czech_Republic: "捷克",
        denmark: "丹麥",
        dominican_Republic: "多米尼亞共和國",
        egypt: "埃及",
        estonia: "愛沙尼亞",
        ethiopia: "衣索比亞",
        finland: "芬蘭",
        france: "法國",
        georgia: "格魯吉亞",
        germany: "德國",
        ghana: "加納",
        greece: "希臘",
        guyana: "蓋亞那",
        honduras: "洪都拉斯",
        hong_Kong_China: "中國香港",
        hungary: "匈牙利",
        iceland: "冰島",
        ireland: "愛爾蘭",
        italy: "義大利",
        india: "印度",
        indonesia: "印尼",
        israel: "以色列",
        iran: "伊朗",
        iraq: "伊拉克",
        japan: "日本",
        kazakstan: "哈薩克共和國",
        kenya: "肯雅",
        korea: "韓國",
        kuwait: "科威特",
        kyrgyzstan: "吉爾吉斯斯坦",
        laos: "老撾",
        latvia: "拉脫維亞",
        lithuania: "立陶宛",
        luxembourg: "盧森堡",
        macao_China: "中國澳門",
        macedonia: "馬其頓",
        malaysia: "馬來西亞",
        malta: "馬爾他",
        mexico: "墨西哥",
        moldova: "莫爾達瓦",
        monaco: "摩納哥",
        mongolia: "蒙古",
        montenegro: "黑山",
        morocco: "摩洛哥",
        myanmar: "緬甸",
        netherlands: "荷蘭",
        new_Zealand: "紐西蘭",
        nepal: "尼泊爾",
        nigeria: "奈及利亞",
        norway: "挪威",
        oman: "阿曼",
        palestine: "巴勒斯坦",
        panama: "巴拿馬",
        paraguay: "巴拉圭",
        peru: "秘魯",
        philippines: "菲律賓",
        poland: "波蘭",
        portugal: "葡萄牙",
        puerto_Rico: "波多黎各",
        qatar: "卡塔爾",
        romania: "羅馬尼亞",
        russia: "俄羅斯",
        republic_of_Trinidad_and_Tobago: "千裡達托貝哥",
        rwanda: "盧安達",
        saudi_Arabia: "沙烏地阿拉伯",
        serbia: "塞爾維亞",
        singapore: "新加坡",
        slovakia: "斯洛伐克",
        slovenia: "斯洛文尼亞",
        south_Africa: "南非",
        spain: "西班牙",
        sri_Lanka: "斯里蘭卡",
        sweden: "瑞典",
        switzerland: "瑞士",
        taiwan_China: "中國臺灣",
        tajikistan: "塔吉克共和國",
        tanzania: "坦尚尼亞",
        thailand: "泰國",
        turkey: "土耳其",
        turkmenistan: "土庫曼共和國",
        ukraine: "烏克蘭",
        united_Arab_Emirates: "阿聯酋",
        united_Kingdom: "英國",
        united_States: "美國",
        uzbekistan: "烏茲別克",
        venezuela: "委內瑞拉",
        vietnam: "越南",
        afghanistan: "阿富汗",
        angola: "安哥拉",
        azerbaijan: "阿塞拜疆",
        bangladesh: "孟加拉",
        belarus: "白俄羅斯",
        belize: "貝裡斯",
        benin: "貝南",
        bhutan: "不丹",
        bolivia: "玻利維亞",
        botswana: "波劄那",
        british_Virgin_Islands: "英屬維京群島",
        burkina_Faso: "伯基納法索",
        burundi: "布隆迪",
        cape_Verde: "佛得角",
        cayman_Islands: "開曼群島",
        central_African_Republic: "中非共和國",
        chad: "查德",
        comoros: "科摩羅",
        the_Republic_of_the_Congo: "剛果（布）",
        democratic_Republic_of_the_Congo: "剛果（金）",
        djibouti: "吉佈提",
        ecuador: "厄瓜多尔",
        el_Salvador: "薩爾瓦多",
        equatorial_Guinea: "赤道幾內亞",
        eritrea: "厄利垂亞",
        fiji: "斐濟",
        gabon: "加蓬",
        gambia: "岡比亞",
        greenland: "格陵蘭",
        guatemala: "瓜地馬拉",
        guinea: "幾內亞",
        haiti: "海地",
        isle_of_Man: "曼島",
        cote_d_Ivoire: "象牙海岸",
        jamaica: "牙買加",
        jordan: "約旦",
        lebanon: "黎巴嫩",
        lesotho: "萊索托",
        liberia: "賴比瑞亞",
        libya: "利比亞",
        madagascar: "馬拉加西",
        malawi: "馬拉威",
        maldives: "瑪律地夫",
        mali: "馬利",
        mauritania: "茅利塔尼亞",
        mauritius: "模裡西斯",
        mozambique: "莫三比克",
        namibia: "納米比亞",
        nicaragua: "尼加拉瓜",
        republic_of_Niger: "尼日尔",
        north_Korea: "朝鮮",
        reunion: "留尼汪",
        san_Marino: "聖馬利諾",
        senegal: "塞內加爾",
        sierra_Leone: "塞拉里昂",
        somalia: "索馬利亞",
        sudan: "蘇丹",
        suriname: "蘇利南",
        eswatini: "史瓦濟蘭",
        syria: "敘利亞",
        togo: "多哥",
        tonga: "東加",
        tunisia: "突尼斯",
        united_States_Virgin_Islands: "美屬維爾京群島",
        uganda: "烏干達",
        uruguay: "烏拉圭",
        vatican: "梵蒂岡",
        yemen: "葉門",
        yugoslavia: "南斯拉夫",
        zambia: "尚比亞",
        zimbabwe: "辛巴威",
        china: "中國",
    }
};
