export default {
    common: {
        expected: "Lợi nhuận dự kiến",
        view: "Xem thêm",
        confirm: "Xác định",
        cancel: "Hủy bỏ",
        deposit: "Nhập tiền",
        withdraw: "Rút tiền",
        noData: "Không có dữ liệu.",
        submit: "Giới thiệu",
        modify: "Sửa đổi",
        hand: "Bàn tay",
        confirm_verify: "Xác nhận xác nhận",
        next: "Tiếp theo",
        complete: "Hoàn thành",
        delete: "Xoá",
		countryCode: "Mã quốc gia",
        no_balance: "Không đủ số dư tài khoản",
    },
	currency: {
		btcusdt: "Viet Nam",
		ethusdt: "Trang chủ",
		ltcusdt: "Viet Nam",
		xrpusdt: "Đồng Ripple",
		adausdt: "Trang chủ Tiền xu",
		eosusdt: "Đồng xu bưởi",
		dotusdt: "Đồng xu Poca",
		trxusdt: "Trang chủ",
		xmrusdt: "Tiền Monero",
		dogeusdt: "Chó đồng xu",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "Đô la Úc Đô la Mỹ",
		fx_sgbpusd: "GBP Đô la Mỹ",
		fx_seurusd: "Đô la Mĩ",
		fx_snzdusd: "Đô la New Zealand (NZD) Đô la Mĩ",
		fx_susdcad: "Đô la Mỹ CAD",
		fx_susdchf: "Đô la Mỹ Thụy Sĩ",
		fx_susdjpy: "Đô la Mĩ JPY",
		fx_saudcad: "Đô la Úc Đô la Canada",
		fx_saudchf: "Đô la Úc Thụy Sĩ",
		fx_saudjpy: "Đô la Úc JPY",
		fx_saudnzd: "Đô la Úc Đô la New Zealand (NZD)",
		fx_scadchf: "Đô la Canada Thụy Sĩ",
		fx_scadjpy: "Đô la Canada JPY",
		fx_schfjpy: "Thụy Sĩ Yên Nhật",
		fx_seuraud: "Euro Đô la Úc",
		fx_seurcad: "Euro Đô la Canada",
		fx_seurgbp: "Euro Bảng Anh",
		fx_seurjpy: "Euro Yên Nhật",
		fx_seurnzd: "Euro Đô la New Zealand (NZD)",
		fx_sgbpaud: "Bảng Anh AUD",
		fx_sgbpchf: "Bảng Anh Thụy Sĩ",
		fx_sgbpjpy: "GBP Yên Nhật",
		fx_sgbpnzd: "Bảng Anh Đô la New Zealand (NZD)",
		fx_scadnzd: "Đô la Canada NZD",
		hf_CL: "Dầu thô WTI New York",
		hf_OIL: "Dầu thô Brent",
		hf_GC: "Vàng New York",
		hf_SI: "New York Bạc",
		hf_HG: "Đồng Mỹ",
		hf_NG: "Mỹ Gas",
		hf_CAD: "Luân Đôn Copper",
		hf_AHD: "London nhôm",
		HX_AAPL: "Táo",
		HX_BABA: "Viet Nam",
		HX_GOOG: "Trang chủ",
		HX_WMT: "Việt Nam",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Viet Nam",
		HX_N225: "Kinh nguyệt 225",
		HX_GDAXI: "Đức DAX30",
		HX_IBEX: "Tây Ban Nha IBEX35",
		HX_SPX: "Chỉ số S&P 500",
		HX_FTSE: "FTSE 100 ở Anh",
		HX_KS11: "Hàn Quốc KOSPI",
		HX_NZ50: "New Zealand 50",
		HX_PSI20: "Bồ Đào Nha PSI20",
		hf_ZSD: "Luân Đôn Kẽm",
		hf_NID: "Luân Đôn Niken",
		hf_PBD: "Luân Đôn Lead",
		hf_SND: "Luân Đôn Tin",
		hf_XAU: "London Gold (Vàng tại chỗ)",
		hf_XAG: "London Silver (bạc tại chỗ)",
		hf_XPT: "Hợp đồng tương lai Platinum",
		hf_XPD: "Tương lai Palladium",
		hf_CT: "Bông Mỹ",
		hf_SM: "Bột đậu nành",
		hf_BO: "Dầu đậu nành",
		HX_SENSEX: "Ấn Độ Mumbai Sensex",
		HX_KLSE: "Thương hiệu: FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "Pháp CAC40",
		HX_SX5E: "Châu Âu Snooker 50",
		HX_ICEXI: "Băng Đảo ICEX",
		HX_ASE: "Hy Lạp Athens ASE",
		HX_OMXC20: "OMX Nhật Bản 20",
		HX_OSEBX: "Na Uy OSEBX",
		HX_OMXSPI: "Thụy Điển OMXSPI",
		HX_AMD: "Chất bán dẫn siêu bền",
		HX_DIS: "Việt",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "Trang chủ",
		HX_NVDA: "Trang chủ",
		HX_INTC: "Thông tin",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Công nghệ Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "Đồng xu Astro",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
    currenctType: {
        title: "Vui lòng chọn tiền tệ",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Đô la Mỹ',
        currenctType15: 'Hồng Kông Dollar',
        currenctType16: 'Đài tệ',
        currenctType17: 'Đồng xu ngựa',
        currenctType18: 'Đồng xu mới',
        currenctType19: 'Yên Nhật',
        currenctType20: 'Châu Âu',
        currenctType21: 'Đô la Úc',
        currenctType22: 'Rupiah',
        currenctType23: 'Bảng Anh',
        currenctType24: 'Thai baht',
        currenctType25: 'Đô la Canada',
        currenctType26: 'Việt Nam',
        currenctType27: 'Hàn Quốc',
        currenctType28: 'Tiền Ma Cao',
    },
    addnew3: {
        direction: "Hướng dẫn",
        sell_price: "Giá bán",
        number: "Số lượng",
        type: "Thời gian đóng cửa",
        buy_price: "Giá mua",
        profit: "Lợi nhuận và lỗ",
        sell_time: "Thời gian bán",
    },
    addnew2: {
        address: {
            account_type: "Loại tài khoản",
            ifsc_code: "Mã IFSC",
            title: "Địa chỉ rút tiền",
            account_number: "Số tài khoản",
            add_btn: "Thêm địa chỉ",
            label_type: "Chọn loại",
            place_type: "Vui lòng chọn loại",
            label_number: "Số tài khoản",
            place_number: "Vui lòng nhập số tài khoản",
            wallet_address: 'Địa chỉ ví',
            bank_name: 'Tên ngân hàng',
            bank_address: "Địa chỉ ngân hàng",
            name: "Tên",
            payee_address: 'Địa chỉ người nhận',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Tên chi nhánh",
            branch_code: "Mã chi nhánh",
            bank_code: "Mã ngân hàng",
            phone: "Số điện thoại",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Vui lòng nhập nội dung",
        },
    },
    addnew: {
        realFirst: "Vui lòng xác thực danh tính trước",
        payFirst: "Vui lòng đặt mật khẩu giao dịch trước",
        password1: "Thay đổi mật khẩu đăng nhập",
        password2: "Thay đổi mật khẩu rút tiền",
        paypassTit1: "Vui lòng đặt mật khẩu rút tiền",
        oldPass: "Mật khẩu cũ",
        allView: "Tất cả đã đọc",
        confirmOrder: "Xác nhận đơn hàng",
        name: "Tên",
        fee: "Phí xử lý",
        orderCycle: "Chọn thời gian",
        loan: "Trang chủ",
        loanTit: "Trợ giúp cho vay",
        loanHostory: "Hồ sơ vay vốn",
        labelProductTitle: "Tên sản phẩm",
        immediateRepayment: "Trả tiền ngay",
        loadAmount: "Số tiền vay dự kiến",
        RepaymentCycle: "Chu kỳ hoàn trả khoản vay",
        day: "Ngày",
        dailyRate: "Lãi suất hàng ngày",
        repaymentMethod: "Phương thức thanh toán",
        lendingCompany: "Công ty cho vay",
        loanTip: "Cho vay tín dụng (đảm bảo hình ảnh rõ ràng)",
        loanTip1: "Tải lên giấy chứng nhận bất động sản",
        loanTip2: "Bằng chứng về thu nhập (quan hệ lao động)",
        loanTip3: "Chi tiết sao kê ngân hàng",
        loanTip4: "Tải lên ảnh mặt trước của ID",
        interest: "Lợi ích",
        repaid: "Đã thanh toán",
        unpaid: "Chưa hoàn trả",
        tab1: "Tiền xu",
        tab2: "Hợp đồng",
        tab3: "Tùy chọn",
        available: "Hạn mức có sẵn",
        pending: "Khóa",
        equivalent: "Liên kết",
        fundTransfer: "Chuyển tiền",
        flashExchange: "Nhấp nháy",
        select_breed: "Chọn loại tiền tệ",
        from: "Từ",
        to: "Đến",
        transferQuantity: "Số lượng chuyển",
        repaymentMethod1:"Hết hạn thanh toán một lần"
    },
    params: {
        product_foreign: "Ngoại hối",
        product_shop: "Hàng hóa",
        product_number: "Chỉ số",
        product_stock: "Cổ phiếu",
        product_coin: "Mã hóa",
        billTypes: [
            { v: 101, name: 'Nạp tiền' },
            { v: 102, name: 'Thêm' },
            { v: 103, name: 'Đóng băng' },
            { v: 104, name: 'Làm tan băng' },
            {v: 105, name: 'Đăng ký'},
            {v: 106, name: 'Đăng ký'},
            { v: 201, name: 'Rút tiền đóng băng' },
            { v: 202, name: 'Khấu trừ' },
            { v: 203, name: 'Rút tiền thành công' },
            { v: 204, name: 'Rút tiền thất bại' },
            { v: 205, name: 'Phí rút tiền' },
            {v: 206, name: 'Chuyển ra'},
            {v: 207, name: 'Chuyển tiếp'},
            {v: 208, name: 'Chuyển tiền xu'},
            {v: 209, name: 'Chuyển tiền.'},
            { v: 301, name: 'Khấu trừ ký quỹ' },
            { v: 302, name: 'Khấu trừ phí thủ tục' },
            { v: 303, name: 'Hoàn tiền ký quỹ' },
            { v: 304, name: 'Lợi nhuận' },
            { v: 305, name: 'Mất tiền' },
            {v: 311, name: 'Tùy chọn mua'},
            {v: 312, name: 'Lợi nhuận tùy chọn'},
            {v: 313, name: 'Tùy chọn trả lại'},
            {v: 314, name: 'Phí xử lý quyền chọn'},
            {v: 315, name: 'Coin mua đóng băng'},
            {v: 316, name: 'Tiền xu mua vào khấu trừ'},
            {v: 317, name: 'Coin Mua Trở Lại'},
            {v: 318, name: 'Tiền mua vào tài khoản'},
            {v: 319, name: 'Tiền mua vào tài khoản'},
            {v: 320, name: 'Bán hàng đóng băng'},
            {v: 321, name: 'Bán đồng xu khấu trừ'},
            {v: 322, name: 'Bán coin trở lại'},
            {v: 323, name: 'Bán đồng xu vào tài khoản'},
            {v: 324, name: 'Bán đồng xu vào tài khoản'},
            {v: 325, name: 'Phí xử lý tiền tệ'},
            {v: 401, name: 'Miner tham gia'},
            {v: 402, name: 'Miner trở lại'},
            {v: 403, name: 'Thu nhập Miner'},
            {v: 404, name: 'Máy đào thoát'},
            {v: 405, name: 'Phí rút tiền máy đào'},
            {v: 411, name: 'Thu nhập vay'},
            {v: 412, name: 'Phí thủ tục vay'},
            {v: 413, name: 'Trả nợ thành công'},
        ],
    },
    lockming: {
        profit: "Gần đây (thu nhập hàng ngày)",
        cycle: "Chu kỳ tài chính",
        limit: "Giới hạn duy nhất",
        dividend_time: "Thời gian phân phối",
        every_day: "Hàng ngày",
        funds: "Tiền ký quỹ",
        back: "Hết hạn trả về",
        ransom: "Early Redemption",
        label_amount: "Pledge amount",
        place_amount: "Enter...",
        available: "available",
        expect_income: "Projected Revenue",
        title: "Locked mining",
        tip: "Mining for endless profits",
        label_today_income: "Expected Today's Revenue",
        label_total_income: "cumulative gain",
        label_order: "Hosted orders",
        label_number: "Single transaction amount",
        daily_return: "Daily return",
        subscribe: "subscribe",
        position: "Position",
        label_state: "state",
        subscribe_time: "Subscription time",
        expiration_time: "Expiration time",
        income: "income",
        errorTip1: "Please enter the amount",
        tip_tit: "Are you sure you want to exit early",
    },
    footer: {
        nav1: "Trang chủ",
        nav2: "Giao dịch",
        nav3: "Vị trí",
        nav4: "Của tôi",
        kline_set1: "Thiết lập giá thị trường",
        kline_set2: "Thiết lập giao dịch",
    },
    login: {
        login: "Đăng nhập",
        register: "Đăng ký",
        type1: "Số điện thoại",
        type2: "Hộp thư",
        login_btn: "Đăng nhập an toàn",
        register_btn: "Đăng ký ngay",
        psd_login: "Đăng nhập mật khẩu",
        verify_login: "Đăng nhập CAPTCHA",
        forget: "Quên mật khẩu?",
        no_account: "Không có tài khoản？",
        have_account: "Đã có tài khoản",
    },
    home: {
        home: {
            nav1: "Bảng xếp hạng tăng giảm",
            nav2: "Bắt đầu nhanh",
            nav3: "Về chúng tôi",
            nav4: "Phần thưởng mời",
            most_follow: "Được quan tâm nhiều nhất",
            news_type1: "Chiến lược",
            news_type2: "Tin tức",
            news_type3: "Lịch tài chính",
            news_tab1: "Tất cả",
            news_tab2: "Ngoại hối",
            news_tab3: "Cổ phiếu",
            news_tab4: "Hàng hóa",
            news_tab5: "Chỉ số",
            news_tab6: "Dữ liệu",
            news_tab7: "Sự kiện",
            news_tab8: "Kỳ nghỉ",
            label_previous: "Giá trị trước",
            label_predictive: "Giá trị dự đoán",
            label_announced: "Thông báo giá trị",
        },
        kilne: {
            label_bp: "Hôm qua nhận",
            label_sp: "Giờ mở",
            label_height: "Cao nhất",
            label_low: "Thấp nhất",
            tip: "Xin chào, tôi là Trợ lý đa dạng RichardGPT",
            row1_tit: "Chiến lược",
            row1_rinei: "Trong ngày",
            row1_short: "Ngắn hạn",
            row1_middle: "Giữa",
            row1_time: "Thời gian phát hành",
            row1_tit2: "Chiến lược giao dịch",
            data_sources: "Nguồn dữ liệu",
            row2_tit1: "Dữ liệu",
            row2_tit2: "Cảm xúc giao dịch",
            row2_label_sell: "Người bán",
            row2_label_buy: "Khách hàng",
            tip2: "Chỉ để tham khảo, không phải là vị trí của bộ phận này",
            tip3: "Cập nhật mỗi 15 phút",
            row2_tit3: "Giá tăng giảm",
            minute: "phút",
            today: "Hôm nay",
            right_tit7: "Phạm vi thay đổi giá",
            now_price: "Giá hiện tại",
            right_low_price: "Giá thấp nhất",
            right_height_price: "Giá cao nhất",
            details: "Chi tiết",
            right_label1: "Số lượng giao dịch duy nhất",
            right_label2: "Đòn bẩy tối đa",
            right_label3: "Kích thước hợp đồng",
            right_label4: "Tổng số vị trí tối đa",
            right_label5: "Chênh lệch giá nổi",
            right_label6: "Phí qua đêm",
            hand: "Bàn tay",
            right_sell: "Bán hàng",
            right_buy: "Mua vào",
            right_collection_time: "Thời gian tính phí",
            right_tip3: "Thu thập từ toàn bộ quy mô giao dịch",
            right_label9: "Phiên giao dịch",
            right_label10: "Thời gian hiện tại",
            right_label11: "Thời gian tiếp theo",
        },
        placeorder: {
            direction_buyPrice: "Giá mua",
            direction_sellPrice: "Giá bán",
            direction_sell: "Bán hàng",
            direction_buy: "Mua vào",
            market_price: "Bảng giá thị trường",
            check_price: "Danh sách chờ",
            sell_tip: "Bán khi giá thỏa mãn điều kiện",
            buy_tip: "Mua khi giá đáp ứng điều kiện",
            trade_number: "Số lượng giao dịch",
            hand: "Bàn tay",
            select_lever: "Chọn đòn bẩy",
            right_tip: "Yêu cầu ký quỹ",
            balance: "Số dư có sẵn",
            label_stop_win_price: "Dừng lại.",
            label_stop_lose_price: "Dừng lỗ",
            expected_profit_loss: "Lợi nhuận và lỗ dự kiến",
            trailing_stop: "Theo dõi dừng lỗ",
        },
        ranking: {
            tit: "Bảng xếp hạng tăng giảm trong ngày",
            p: "Nắm bắt điểm nóng, nắm bắt cơ hội",
            renew: "Cập nhật trong",
            growth_chart: "Bảng tăng trưởng",
            drop_list: "Bảng giảm giá",
            show_closed_market: "Giới thiệu thương hiệu Hugh Market",
            filter1: "Tất cả",
            filter2: "Ngoại hối",
            filter3: "Hàng hóa",
            filter4: "Cổ phiếu",
            filter5: "Chỉ số",
            filter6: "Mã hóa",
            td1: "Xếp hạng",
            td2: "Xu hướng",
            td3: "Giá bán/Giá bán trong ngày",
        },
        remind: {
            now_sell_price: "Giá bán hiện tại",
            direction_buyPrice: "Giá mua",
            direction_sellPrice: "Giá bán",
            direction_sell: "Bán hàng",
            direction_buy: "Mua vào",
            equal: "Khi",
            p: "Nhắc nhở tôi khi bạn đạt được mức giá dưới đây",
            btn1: "Đặt nhắc nhở",
            list_tit: "Danh sách nhắc nhở",
        },
    },
    my: {
        report: {
            tit: "Báo cáo tài khoản",
            tab1: "Báo cáo",
            tab2: "Danh sách thanh toán hàng ngày",
            tab3: "Danh sách thanh toán hàng tháng",
            history_tit: "Báo cáo lịch sử giao dịch",
            account_tit: "Báo cáo tài khoản",
            filter: "Lọc",
            tip1: "Vui lòng chọn phạm vi ngày thích hợp để xuất báo cáo lịch sử giao dịch của bạn。",
            tip2: "Thời gian tùy chỉnh",
            email_send: "Gửi đến hộp thư",
        },
        accountMsg: {
            tit: "Thông tin tài khoản",
            label1: "Thông tin cá nhân",
        },
        bill: {
            tit: "Nguồn vốn chi tiết",
            all: "Tất cả",
            time: "Thời gian",
        },
        feedback: {
            tit: "Đề xuất chức năng",
        },
        identity: {
            tit: "Xác thực",
            type1: "Chứng minh thư",
            type2: "Giấy phép lái xe",
            type3: "Hộ chiếu",
            tip: "Tôi xác nhận rằng tất cả các thông tin và tài liệu được cung cấp là đầy đủ, trung thực và chính xác.",
        },
        my: {
            is: "Đã",
            no: "Chưa xác minh",
            verify: "Đã kiểm tra",
            id_verify: "Xác thực",
            verify_tip: "Hoàn thành xác thực để nạp tiền giao dịch",
            no_verify: "Kiểm tra ngay",
            balance: "Số dư có sẵn",
            profit_loss: "Lợi nhuận và lỗ",
            marginLevel: "Mức ký quỹ",
            btn1: "Chuyển sang tài khoản thực",
            deposit_withdraw_record: "Hồ sơ tiền xuất nhập cảnh",
            capital_flow_details: "Nguồn vốn chi tiết",
            welfare_center: "Trung tâm phúc lợi",
            tip1: "Bạn có",
            tip2: "Phần thưởng đang chờ nhận.",
            feedback: "Đề xuất chức năng",
            report: "Báo cáo tài khoản",
            setting: "Thiết lập",
        },
        news: {
            tit: "Thông báo",
        },
        record: {
            tit: "Hồ sơ tiền xuất nhập cảnh",
            cycle: "Chu kỳ",
        },
        share: {
            tit: "Mời bạn bè",
            invite_success: "Mời bạn bè thành công",
            tip1: "Tiền thưởng",
            tip2: "Mời bạn bè tối đa",
            tip3: "Mời bạn bè nhận tiền mặt",
        },
        welfare: {
            tit: "Trung tâm phúc lợi",
            tab1: "Thẻ cuộn",
            tab2: "Hoạt động",
            history_record: "Lịch sử",
            period_validity: "Ngày hết hạn",
        },
        wallet: {
            tit: "Ví tiền",
            addNew: "Thêm ví mới",
        },
        withdraw: {
            tit: "Rút tiền",
            addNew: "Thêm ví mới",
        },
    },
    order: {
        demo_account: "Tài khoản Demo",
        real_account: "Tài khoản thực",
        tip: "Sau khi mở có thể giao dịch nạp tiền",
        tab1: "Giữ vị trí",
        tab2: "Danh sách chờ",
        tab3: "Lịch sử",
        list_empty: "Hiện tại không có lệnh chờ",
        start_trade: "Bắt đầu giao dịch",
        hand_tip: "Phạm vi có thể nhập",
        kline: {
            direction_sell: "Bán hàng",
            direction_buy: "Mua vào",
            open_price: "Giá mở vị trí",
            now_price: "Giá hiện tại",
            trade_account: "Tài khoản giao dịch",
            demo_account: "Tài khoản Demo",
            order_id: "Đặt hàng ID",
            open_time: "Thời gian mở vị trí",
            stop_profit: "Dừng lại.",
            stop_loss: "Dừng lỗ",
            trailing_stop: "Theo dõi dừng lỗ",
            float_profit_loss: "Nổi và lỗ",
            overnight_fee: "Phí qua đêm",
            margin: "Ký quỹ",
            maintainsMargin: "Ký quỹ duy trì",
            open_value: "Giá trị mở vị trí",
            now_value: "Giá trị hiện tại",
            notset: "Chưa đặt",
            close_position: "Đóng vị trí",
            close_number: "Số lượng bình thường",
            onclick_close: "Đóng vị trí bằng một cú nhấp chuột",
            btn: "Xác nhận đóng vị trí",
            balance: "Số dư có sẵn",
            profit_loss: "Lợi nhuận và lỗ",
            netValue: "Giá trị ròng",
            marginLevel: "Mức ký quỹ",
        },
        modify: {
            tit: "Sửa đổi lệnh giữ",
            expected_profit_loss: "Lợi nhuận và lỗ dự kiến",
        },

    },
    setting: {
        security: {
            modify_password_tit: "Đặt mật khẩu mới",
            verify_email_tit: "Kiểm tra hộp thư",
            verify_password_tit: "Xác minh mật khẩu của bạn",
            verify_password_tip: "Nhập mật khẩu đăng nhập Richard của bạn để tiếp tục",
            verify_phone_tit: "Kiểm tra số điện thoại",
            tit: "Bảo mật tài khoản",
            tip: "Để bảo vệ tài khoản của bạn và chấp nhận thông báo dịch vụ của chúng tôi, chúng tôi khuyên bạn nên mở ít nhất một loại xác thực.",
            label1: "Thay đổi mật khẩu",
            label2: "Đăng xuất tài khoản",
            label3: "Xác minh kép",
            label4: "Quản lý thiết bị",
            tip2: "Yêu cầu xác minh khi thiết bị mới đăng nhập",
        },
        tradenews: {
            tit: "Hướng dẫn giao dịch",
            tab1: "Bắt đầu nhanh",
            tab2: "Học nâng cao",
            tab3: "Trung tâm trợ giúp",
            tab4: "Về Richard",
            viewMore: "Xem thêm",
            details: {
                tit: "Giới thiệu Forex",
                tip1: "Ước tính thời gian học",
                tip2: "phút",
            },
            tab1Row: {
                tit: "Đây là một lớp học nhanh chóng học cách giao dịch với Richard",
                tit1: "Giao dịch trên Richard như thế nào?",
                tit2: "Cách mở tài khoản thật？",
                tit3: "Cách mở một vị trí giao dịch？",
                tit4: "Làm thế nào để thiết lập Earning Stop Loss？",
                tit5: "Cách đóng một vị trí？",
                p1: "Bạn có thể giao dịch chủ đề này bằng cách mua hoặc bán. Mở một vị trí trong Richard, điều này có nghĩa là bạn đang ở trên",
                p2: "Biến động giá của sản phẩm cơ bản được mua và bán",
                p3: "Ví dụ: nếu bạn mong đợi giá vàng tăng, hãy mua sản phẩm đó; Thay vào đó, bạn dự đoán giá vàng sẽ giảm và bán sản phẩm. Nếu tương lai phù hợp với mong đợi, bạn có thể",
                p4: "Lợi nhuận từ",
                p5: "Ngoài ra, Richard cung cấp ''giao dịch đòn bẩy'. Bạn có thể sử dụng đòn bẩy để giao dịch nhiều lô hơn với cùng một số tiền.",
                p6: "Đòn bẩy sẽ khuếch đại lợi nhuận cũng như lỗ",
                p7: "Điều này còn được gọi là 'giao dịch ký quỹ'. Khi bạn mở một vị trí, tài khoản Richard của bạn sẽ bị khấu trừ một số dư nhất định như",
                p8: "Ký quỹ ban đầu",
                p9: "Chọn sản phẩm và hướng mua và bán",
                p10: "Giao dịch",
                p11: "Nhấn vào 'Mua/Bán' để hoàn thành lệnh.",
                p12: "Thiết lập'stop-in'hoặc'stop-out' có thể giúp bạn",
                p13: "Khóa lợi nhuận kịp thời và hạn chế lỗ",
                p14: "Bạn có thể click vào trang Open Success",
                p15: "Dừng lỗ/Stop loss",
                p16: "Sau khi cài đặt, bạn có thể nhấp vào 'Sửa đổi' 'chưa cân bằng để dừng lỗ.",
                p17: "Đặt hoặc hủy",
                p18: "Bạn có thể xem lợi nhuận và lỗ trong'Vị trí đóng'",
            },
            tab3Row: {
                h1: "Chào mừng đến với Richard",
                h2: "Trung tâm trợ giúp",
                tit0: "Tất cả câu hỏi",
                tit1: "Tài khoản Demo",
                tit2: "Mở tài khoản",
                tit3: "Nạp tiền và rút tiền",
                tit4: "Thiết lập nền tảng",
                tit5: "Hoạt động giao dịch",
                tit6: "Phí&lệ phí",
                tit7: "An toàn tài chính&Quy định",
                tit1Tit1: "Richard có cung cấp tài khoản demo không?",
                tit1Cont1: "<p>Richard cung cấp tài khoản demo cho hoạt động của khách hàng và bạn có thể giao dịch và làm quen với hoạt động của nền tảng mà không gặp bất kỳ rủi ro nào bằng cách sử dụng nền tảng web và ứng dụng di động của chúng tôi.</p>",
                tit1Tit2: "Cách tạo tài khoản demo?",
                tit1Cont2: "<p>Bạn chỉ cần nhấp vào đây để điền địa chỉ email hoặc số điện thoại di động của bạn và thiết lập mật khẩu cá nhân của bạn để hoàn thành đăng ký để có được một tài khoản demo</p>",
                tit1Tit3: "Tài khoản demo có ích lợi gì??",
                tit1Cont3: "Tài khoản demo và tài khoản thực hầu hết đều giống nhau về giao diện giao dịch, dữ liệu và hoạt động, với 50.000 đô la tiền ảo trong tài khoản demo, mục đích là để khách hàng làm quen với các chức năng của nền tảng thông qua hoạt động demo mà không có rủi ro về tiền. </p>",
                tit1Tit4: "Cách nạp tiền vào tài khoản demo?",
                tit1Cont4: "<p>Bạn không cần phải nạp tiền vào tài khoản demo, khi bạn đăng ký tài khoản demo, bạn sẽ có 50.000 USD hoặc AUD trong tài khoản.</p><p>Khi tài khoản demo có giá trị ròng dưới 200 USD hoặc AUD, hệ thống sẽ tự động nạp lại tài khoản của bạn cho 50.000 USD hoặc AUD.</p>",
                tit1Tit5: "Tài khoản demo có thời hạn sử dụng không?",
                tit1Cont5: "<p>Tài khoản demo có giá trị trong 90 ngày và sau 90 ngày nếu bạn không mở tài khoản thật, tài khoản demo sẽ bị đóng băng và không thể thực hiện giao dịch.。</p><p>Nếu bạn mở một tài khoản thực trong vòng 90 ngày, tài khoản demo sẽ có hiệu lực trong thời gian dài. Tiếp tục hoạt động trên tài khoản demo ngay cả sau khi mở tài khoản thực。</p>",
                tit2Tit1: "Richard Có bao nhiêu loại tài khoản được cung cấp?  ",
                tit2Cont1: "<p>Chúng tôi chỉ cung cấp tài khoản giao dịch tiêu chuẩn và chuyên nghiệp cho các cá nhân, không hỗ trợ mở tài khoản công ty và tài khoản chung.</p>",
                tit2Tit2: "Tôi có thể mở tài khoản giao dịch bằng loại tiền tệ nào?",
                tit2Cont3: "<p>Khi bạn mở tài khoản, hệ thống sẽ tự động xác định đồng tiền thanh toán của bạn dựa trên khu vực của bạn và sẽ không thay đổi.</p>",
                tit2Tit4: "Cách mở tài khoản thật?",
                tit2Cont4: "<p>Mở tài khoản thật vui lòng làm theo các bước dưới đây</p><p>1. Truy cập trang đăng ký Richard theo các mẹo: đăng ký số điện thoại di động hộp thư, đặt mật khẩu đăng nhập, tự động tạo sau khi gửi xác nhận</p><p></p><p></p>",
                tit3Tit1: "Tôi có thể nạp tiền bằng cách nào??",
                tit3Cont1: "<p>Richard Richard cung cấp các phương thức nạp tiền khác nhau cho khách hàng lựa chọn, bao gồm và có thể nhiều hơn những điều sau đây::</p><p>1.Visa/Mastercard - Chuyển khoản tức thời</p><p>2.Thanh toán trực tuyến thường được thực hiện sớm hơn 1 ngày.</p><p>3.Ví điện tử (ví dụ: Skrill, Momo, Zalo.Touch'n GO và Boost, v.v.) - thường là Instant to Account</p><p>4.Thẻ ATM - thường là thanh toán ngay lập tức</p><p>5.Ngân hàng trực tuyến di động QR - thường là ngay lập tức vào tài khoản</p>",
                tit3Tit2: "Tôi có thể sử dụng tài khoản ngân hàng/thẻ tín dụng hoặc rút tiền dưới tên của bên thứ ba không?",
                tit3Cont2: "<p>Richard không chấp nhận tiền gửi từ bên thứ ba. Vui lòng sử dụng tài khoản/thẻ ngân hàng cá nhân đứng tên bạn để nạp hoặc rút tiền. Nếu bạn sử dụng tên của người khác hoặc tài khoản ngân hàng/thẻ công ty, tiền sẽ được trả lại.</p>",
                tit3Tit3: "Làm thế nào để yêu cầu rút tiền từ tài khoản Richard?",
                tit3Cont3: "<p>Sau khi đăng nhập vào trang web, bạn có thể nhấp vào Rút tiền, chọn tài khoản ngân hàng hoặc Visa/Mastercard và điền vào số tiền rút, sau đó nhấn [Gửi] và sau khi xác định dữ liệu chính xác, Richard sẽ xử lý đơn đăng ký của bạn trong vòng 1-2 ngày làm việc.</p>",
                tit4Tit1: "Richard cung cấp đòn bẩy giao dịch bao nhiêu lần?",
                tit4Cont1: "<p>Hệ số đòn bẩy được cung cấp bởi nền tảng Richard được thiết lập theo quy định của cơ quan quản lý và các sản phẩm khác nhau có mức đòn bẩy tối đa khác nhau.。</p>",
                tit4Tit2: "Cách thay đổi mật khẩu của tôi?",
                tit4Cont2: "<p>Sau khi đăng nhập vào nền tảng, bạn có thể chọn [Thêm] - [Cài đặt bảo mật] --T Thay đổi mật khẩu] để đặt lại mật khẩu mới</p>",
                tit4Tit3: "Làm thế nào để đăng nhập vào nền tảng giao dịch Richard?",
                tit4Cont3: "<p>App:Đi đến 'My' và nhấp vào 'Đăng nhập l Đăng ký' ở phía trên bên trái để đến trang đích.。</p>Web: Nhấp vào 'Đăng nhập' ở góc trên bên phải của trang web chính thức Richard để vào trang đích。<p></p><p></p><p></p><p></p>",
                tit4Tit4: "Platform nhắc nhở như thế nào?",
                tit4Cont4: "<p>Richard nhắc nhở qua email, SMS, nền tảng push.</p>",
                tit5Tit1: "Richard cung cấp spread như thế nào?",
                tit5Cont1: "<p>Chúng tôi tính phí chênh lệch giá thấp và không tính phí hoa hồng. Trong khi chênh lệch là thả nổi, chênh lệch thực tế được tính sẽ phụ thuộc vào loại bạn đang giao dịch, tùy thuộc vào nền tảng giao dịch được hiển thị ngay lập tức.。</p>",
                tit5Tit2: "Cách thiết lập market order?",
                tit5Cont2: "<p>Nhấp vào [Giao dịch] (Trade) sẽ hiển thị tất cả các sản phẩm mà nền tảng có thể cung cấp để giao dịch, bạn có thể nhập mã giống hoặc tên vào hộp nhập bên phải để tìm kiếm và chọn một sản phẩm Nhấp vào [Mua/Bán] SELL/BUY sẽ bật lên cửa sổ giao dịch, trong đó bạn có thể thấy giá hiện tại và mức ký quỹ mong muốn. Bạn có thể điều chỉnh số lượng giao dịch mở theo cách thủ công, thiết lập điểm dừng doanh thu để kiểm soát rủi ro</p>",
                tit5Tit3: "Làm thế nào để tạo một lệnh chờ?",
                tit5Cont3: "<p>Chọn một sản phẩm tại [Giao dịch] (Trade) Nhấp vào [Mua/Bán] (SELL/BUY) sẽ bật lên cửa sổ giao dịch, trong cửa sổ giao dịch</p>",
                tit5Tit4: "Cách đóng một vị trí?",
                tit5Cont4: "<p>Để đóng một vị trí, bên trong vị trí r (Position), nhấp vào Xem vị trí Mở (Open) trên nền tảng, sau đó nhấp vào vị trí bạn muốn đóng, sau đó nhấp vào nút Đóng (Close) ở bên phải.。</p>",
                tit5Tit5: "Cách sửa đổi hoặc xóa lệnh chờ?",
                tit5Cont5: "<p>Để sửa đổi hoặc xóa lệnh chờ, trong Position, hãy nhấp vào Xem lệnh chờ trên nền tảng và chọn lệnh chờ mà bạn muốn sửa đổi hoặc xóa.</p>",
                tit6Tit1: "Việc sử dụng sàn giao dịch có bị tính phí xử lý hay không?",
                tit6Cont1: "<p>Richard là một nền tảng giao dịch hoàn toàn miễn phí hoa hồng. Chúng tôi tính phí chênh lệch giá thấp và tùy thuộc vào tình hình giao dịch cụ thể của bạn, nền tảng có khả năng phát sinh thêm phí, chẳng hạn như lãi qua đêm。</p>",
                tit6Tit2: "Có bất kỳ chi phí nào để nạp tiền không??",
                tit6Cont2: "<p>Chúng tôi sẽ không tính bất kỳ khoản phí nào liên quan đến tiền gửi cho khách hàng, nhưng có thể có một khoản phí xử lý cho việc thanh toán hoặc chuyển khoản của bạn. Chúng tôi khuyên bạn nên kiểm tra với các ngân hàng có liên quan nếu họ tính phí bạn bất cứ điều gì。</p>",
                tit6Tit3: "Có chi phí rút tiền không??",
                tit6Cont3: "<p>Richard sẽ không tính bất kỳ khoản phí nào liên quan đến việc rút tiền cho khách hàng trừ khi số tiền rút tiền của bạn thấp hơn yêu cầu tối thiểu hoặc vượt quá số tiền rút tiền miễn phí tối đa trong tháng. Để biết thêm chi tiết, vui lòng xem Chính sách rút tiền của chúng tôi。</p>",
                tit7Tit1: "Số tiền tôi gửi vào tài khoản Richard có được sử dụng cho các mục đích khác không?",
                tit7Cont1: "<p>Không đâu. Tiền gửi của khách hàng bán lẻ được giữ riêng trong tài khoản ủy thác khi cần thiết theo quy định của pháp luật. Khi gửi tiền vào tài khoản thanh toán của khách hàng hoặc rút tiền từ tài khoản thanh toán của khách hàng hoặc thực hiện thanh toán, Richard tuân thủ nghiêm ngặt các quy tắc liên quan đến thanh toán của khách hàng theo quy định của pháp luật.。</p>",
                tit7Tit2: "Nếu Richard đóng cửa hoặc mắc nợ, tiền của tôi có bị ảnh hưởng không?",
                tit7Cont2: "<p>Tiền gửi của khách hàng bán lẻ được giữ riêng trong tài khoản ủy thác khi cần thiết theo quy định của pháp luật. Trong trường hợp Richard thất bại, bị tước giấy phép theo lệnh hoặc bất kỳ trường hợp nào không thể hoạt động liên tục, tiền của khách hàng vẫn có thể bị cô lập và bị giới hạn bởi các quy định về tiền của khách hàng theo luật điều tiết.</p>",
                tit7Tit3: "Richard có tuân thủ luật pháp không?",
                tit7Cont3: "<p>Công ty tuân thủ đầy đủ các quy định của Luật Doanh nghiệp và Luật Quản lý Tài chính và là một nhà cung cấp dịch vụ tài chính được ủy quyền và giám sát chặt chẽ bởi các cơ quan quản lý có liên quan. Công ty cung cấp sự bảo đảm đầy đủ và đầy đủ cho sự an toàn của tiền của khách hàng theo các yêu cầu nghiêm ngặt của quy định.。</p>",
                tit7Tit4: "Dữ liệu cá nhân của khách hàng có an toàn không??",
                tit7Cont4: "<p>Richard tuân thủ nghiêm ngặt các quy định của Quy định Bảo vệ Dữ liệu Cá nhân và Dữ liệu Cá nhân của Khách hàng không được tiết lộ. Công ty sẽ đảm bảo rằng nhân viên tuân thủ các tiêu chuẩn nghiêm ngặt về an ninh và bảo mật theo luật định và tập trung cao độ vào việc sắp xếp bảo mật và sử dụng dữ liệu của khách hàng. Chi tiết có thể được tìm thấy trong các tài liệu tiết lộ pháp lý của công ty.。</p>",
            },
            tab4Row: {
                span: "Phương pháp kiểm tra Q",
                p1: "Richard là câu trả lời cho những người đang tìm kiếm một nền tảng phái sinh cryptocurrency cấp chuyên nghiệp. Mục tiêu của chúng tôi là tạo ra một thị trường hiệu quả và công bằng, kết nối các nhà giao dịch từ mọi nền tảng và phong cách giao dịch. Công suất động cơ phù hợp cao, độ trễ thấp, quản lý rủi ro tiên tiến và thanh khoản cao làm cho Richard trở thành một người chơi độc đáo trên thị trường。",
                p2: "Mục tiêu ban đầu của Richard là tạo ra sàn giao dịch quyền chọn tiền điện tử đầu tiên trên thế giới. Mặc dù đây là một nhiệm vụ vô cùng khó khăn, nhóm đã hoàn thành công việc phát triển hệ thống trong hơn hai năm. Tháng 6 năm 2016, Richard chính thức ra mắt。",
                p3: "Richard được thành lập tại Melbourne, Úc bởi một đội ngũ giàu kinh nghiệm và kiến thức về giao dịch tài chính và công nghệ tài chính. Triết lý của Richard là giao dịch trở nên dễ dàng và thân thiện hơn.",
                p4: "Hiện tại, khách hàng của chúng tôi có thể giao dịch hợp đồng vĩnh viễn, hợp đồng tương lai và hợp đồng quyền chọn. Richard đang phát triển nhanh chóng và là một trong những sàn giao dịch hàng đầu cung cấp hợp đồng tương lai tiền điện tử và giao dịch hợp đồng vĩnh viễn. Ngoài ra, Richard vẫn là sàn giao dịch hàng đầu cung cấp các tùy chọn thanh toán tiền điện tử theo phong cách châu Âu và tiếp tục thiết lập các tiêu chuẩn cho ngành công nghiệp.。",
                p5: "Richard Holding được ủy quyền và quản lý bởi Cơ quan Tiền tệ Quần đảo Cayman (CIMA) theo giấy phép SIB 1612446. Để biết thêm chi tiết về giấy phép lái xe Cayman, hãy truy cập trang web chính thức của CIMA tại www.cima.ky (",
                p6: ") Tiến hành truy vấn. RichardGlobal có giấy phép dịch vụ tài chính (AFSL 398528) được ủy quyền bởi Ủy ban Chứng khoán và Đầu tư Úc (ASIC). Để biết thêm chi tiết về giấy phép Úc, hãy truy cập trang web chính thức của ASIC tại www.asic.gov.au (",
                p7: ") Tiến hành truy vấn. Richard International được ủy quyền và quản lý bởi Ủy ban Dịch vụ Tài chính Mauritius (FSC) theo giấy phép số GB20025791. Để biết chi tiết về giấy phép Mauritius, hãy truy cập trang web chính thức của FSC tại www.fscmauritius.org(",
                p8: ")Tiến hành truy vấn. Tất cả các hoạt động kinh doanh của Richard đều được tiến hành và tuân thủ tất cả các quy định nghiêm ngặt.",
                p9: "Bối cảnh",
                p10: "Richard được thành lập tại Melbourne, Úc bởi một đội ngũ giàu kinh nghiệm và hiểu biết trong lĩnh vực giao dịch tài chính và fintech.",
                p11: "Triết lý của Richard là giao dịch trở nên dễ dàng và thân thiện hơn. Chiến lược và mô hình kinh doanh tổng thể của chúng tôi cho phép chúng tôi có cái nhìn sâu sắc và nhận thức toàn cầu về sự phát triển của ngành và nhu cầu thị trường, cho phép phân bổ nguồn lực có mục tiêu hơn, liên tục đổi mới công nghệ và tối ưu hóa hiệu quả liên tục mang lại trải nghiệm giao dịch thuận tiện và thân thiện hơn cho khách hàng",
                p12: "Tại sao chọn",
                p13: "Điều kiện giao dịch ngưỡng thấp",
                p14: "Nền tảng giao dịch đơn giản và trực quan",
                p15: "Chi phí giao dịch cạnh tranh",
                p16: "Được quy định bởi cơ quan công nghiệp",
                p17: "Mức độ hỗ trợ trực tuyến cao",
                p18: "Bảo vệ số dư âm",
                p19: "Giải thưởng và danh dự",
                p20: "Richard tự hào nhận được nhiều giải thưởng như [Nhà môi giới đa tài sản tốt nhất] từ Giải thưởng Tài chính Thế giới, Nền tảng giao dịch di động tốt nhất Châu Á Thái Bình Dương [Nhà môi giới Fintech phát triển nhanh nhất thế giới] từ Tạp chí Global BrandsMagazine, tất cả đều khẳng định những nỗ lực và nỗ lực của nhóm.",
                p21: "Nhà môi giới đa tài sản tốt nhất",
                p22: "Ý tưởng hoạt động",
                p23: "Giá trị của chúng tôi",
                p24: "Chúng tôi là một trong những nền tảng giao dịch tiền điện tử phát triển nhanh nhất trên thế giới và chúng tôi tin rằng các giá trị cốt lõi của chúng tôi là động lực thúc đẩy thành công này.。",
                p25: "Trung thực và chính trực",
                p26: "Niềm tin của người dùng là rất quan trọng; Do đó, chúng tôi tiến hành kinh doanh với các tiêu chuẩn cao nhất về tính toàn vẹn bằng cách cung cấp các hệ thống quản lý rủi ro công bằng, đối xử bình đẳng với tất cả người tham gia thị trường và các giải pháp bảo mật tiên tiến.。",
                p27: "Chất lượng dịch vụ",
                p28: "Giao dịch tiền điện tử là một quá trình 24/7 không biên giới, trong đó chất lượng dịch vụ không bị ảnh hưởng. Khách hàng là giá trị quan trọng nhất của chúng tôi; Do đó, mục tiêu của chúng tôi là cung cấp trải nghiệm giao dịch trơn tru và không bị gián đoạn. Mục tiêu của chúng tôi là đạt được điều này bằng cách liên tục mở rộng và cải thiện kiến trúc hệ thống của chúng tôi, giảm thiểu thời gian bảo trì và cung cấp dịch vụ khách hàng kịp thời và đa ngôn ngữ.。",
                p29: "Sáng tạo",
                p30: "Trong môi trường cryptocurrency nhịp độ nhanh, đổi mới là điều cần thiết. Richard là một công ty dựa trên giải pháp được thiết kế để cung cấp trải nghiệm giao dịch tốt nhất cho hàng triệu người dùng, một mục tiêu bắt nguồn từ kiến trúc hệ thống có thể mở rộng và hiệu suất cao của chúng tôi.。",
                p31: "Tại sao chọn Derivatives？",
                p32: "Có rất nhiều lợi ích khi giao dịch phái sinh - phí giao dịch thấp hơn, đòn bẩy cao hơn, thanh khoản cao hơn và dễ dàng bán khống hơn。",
                p33: "Giao dịch phái sinh cũng cho phép các chiến lược giao dịch đa dạng hơn. Các nhà giao dịch có thể sử dụng các công cụ phái sinh để phòng ngừa rủi ro, chênh lệch giá hoặc giao dịch đầu cơ.。",
                p34: "Công nghệ",
                p35: "Nó đã được phát triển hơn hai năm trước khi đi vào hoạt động để đảm bảo một hệ thống hiệu suất cao.。",
                p36: "Khung của nền tảng này được phát triển để đảm bảo rằng một số lượng lớn các yêu cầu có thể được xử lý với độ trễ thấp. Chúng tôi đã phát triển một công cụ phù hợp đặc biệt cho Richard và tất cả công nghệ của chúng tôi là độc quyền。",
                p37: "Kể từ khi ra mắt, hệ thống quản lý rủi ro và thanh toán bù trừ gia tăng của chúng tôi đảm bảo không có tổn thất xã hội hóa.。",
                p38: "Tại Richard, chúng tôi tin vào tương lai của tiền điện tử. Mục tiêu của chúng tôi là tiếp tục đi đầu trong thị trường phái sinh tiền điện tử. Chúng tôi hình dung một tương lai mà tiền điện tử sẽ được sử dụng bởi tất cả mọi người và được giao dịch bởi hàng triệu người. Chúng tôi đã sẵn sàng cho tương lai và hệ thống của chúng tôi cũng vậy.。",
            },
        },
        language: {
            tit: "Chọn ngôn ngữ",
        },
        market: {
            tit: "Thiết lập giá thị trường",
            tit1: "Hiển thị giá",
            tit2: "Màu sắc tăng và giảm",
            buy_price: "Giá mua",
            buy_price_tip: "Vẽ đường K theo giá Bid",
            sell_price: "Giá bán",
            sell_price_tip: "Vẽ đường K với giá bán",
            color1: "Xanh lá cây tăng đỏ giảm",
            color2: "Đỏ lên, xanh xuống.",
        },
        notice: {
            tit: "Thiết lập thông báo",
            tit1: "Mở kênh thông báo",
            p1: "Vui lòng mở/xác minh các kênh thông báo sau đây, không bỏ lỡ các tin nhắn quan trọng!",
            message: "Tin nhắn",
            push: "đẩy",
            verify: "Kiểm tra",
            verified: "Đã kiểm tra",
            email: "Thư điện tử",
            type1: "Phân loại Marketing",
            tit2: "Thông báo chiến dịch tiếp thị",
            desc2: "Thông tin về mặt bằng ưu đãi, hoạt động vận hành, v. v.",
            tit3: "Hướng dẫn vận hành",
            desc3: "Tài liệu kiến thức phong phú và chuyên nghiệp",
            tit4: "Thông tin nhắc nhở",
            desc4: "Thông tin tài chính và tin tức gần đây",
            tit5: "Cảnh báo sóng thông minh",
            desc5: "Bao gồm các biến động giá giống quan trọng giúp bạn nhanh chóng nắm bắt cơ hội",
            type2: "Lớp báo cáo",
            tit6: "Báo cáo tài khoản",
            desc6: "Báo cáo tài khoản của bạn sẽ được gửi hàng tháng.",
            cycle: "Chu kỳ",
            month: "Hàng tháng",
        },
        protocol: {
            tit: "Tuyên bố&Thỏa thuận",
            label1: "Chính sách bảo mật",
            label2: "Tuyên bố công bố sản phẩm",
            label3: "Thỏa thuận khách hàng",
            label4: "Tuyên bố công bố rủi ro",
        },
        setting: {
            tit: "Thiết lập",
            label1: "Cài đặt bảo mật",
            label2: "Thiết lập giá thị trường",
            label3: "Thiết lập giao dịch",
            label4: "Ngôn ngữ",
            label5: "Chế độ chủ đề",
            label6: "Thiết lập thông báo",
            label7: "Hướng dẫn giao dịch",
            label8: "Tuyên bố&Thỏa thuận",
            label9: "Làm sạch bộ nhớ cache",
            label10: "Số phiên bản",
            logout: "Đăng nhập",
        },
        theme: {
            tit: "Chế độ chủ đề",
            theme1: "Chế độ màu sáng",
            theme2: "Chế độ tối",
        },
        trade: {
            tit: "Thiết lập giao dịch",
            onclick_close: "Đóng vị trí bằng một cú nhấp chuột",
        }
    },
    trade: {
        fliter_type1: "Tự chọn",
        fliter_type2: "Thị trường",
        place_search: "Nhập tên giống/tìm kiếm mã",
        fliter_type3: "Nóng bỏng",
        fliter_type4: "Ngoại hối",
        fliter_type5: "Hàng hóa",
        fliter_type6: "Cổ phiếu",
        fliter_type7: "Chỉ số",
        fliter_type8: "Mã hóa",
        tip1: "Hoàn thành xác minh danh tính để nạp tiền giao dịch, đầu tư toàn cầu",
        sell: "Bán hàng",
        buy: "Mua vào",
        add_optional: "Thêm tùy chọn",
        optional_recommend: "Đề xuất tự chọn",
        change_batch: "Đổi một mẻ khác.",
        edit_tit: "Chỉnh sửa tự chọn",
        breed: "Đa dạng",
        topping: "Đặt đỉnh",
        sort: "Sắp xếp",
        all: "Chọn tất cả",
    },
    table: {
        label_breed: "Đa dạng",
        label_direction: "Hướng dẫn",
        label_price: "Giá cả",
        label_operate: "Hoạt động",
        empty_tip1: "Tạm thời không nhắc nhở",
        empty_tip2: "Không có hồ sơ tiền gửi",
        empty_tip3: "Không có hồ sơ rút tiền",
        label_amount: "Số lượng",
        label_type: "Loại",
        label_time: "Thời gian",
        label_name: "Tên",
        label_email: "Thư điện tử",
        label_address: "Quốc gia cư trú",
        label_account: "Số tài khoản giao dịch",
        label_currency: "Tiền tệ cơ bản",
        label_company: "Mở tài khoản công ty",
    },
    form: {
        to: "Đến",
        start_time: "Thời gian bắt đầu",
        end_time: "Thời gian kết thúc",
        week: "Gần một tuần",
        month: "Gần một tháng",
        threeMonth: "Gần 3 tháng",
        sixMonth: "Gần nửa năm",
        label_feedback: "Câu hỏi và đề xuất",
        place_feedback: "Vui lòng nhập câu hỏi hoặc đề xuất của bạn",
        label_img: "Hình ảnh",
        label_img_tip: "Chọn, cung cấp ảnh chụp màn hình câu hỏi",
        feedback_tip: "Nếu bạn có câu hỏi khẩn cấp, vui lòng liên hệ",
        online: "Hỗ trợ trực tuyến",
        label_cardNumber: "Số giấy tờ",
        label_realName: "Họ tên",
        place_realName: "Vui lòng nhập tên của bạn",
        message_realName: "Vui lòng nhập tên đầy đủ",
        label_firstName: "Họ",
        place_firstName: "Vui lòng nhập họ của bạn",
        message_firstName: "Vui lòng nhập họ",
        label_lastName: "Tên",
        place_lastName: "Vui lòng nhập tên đệm của bạn",
        message_lastName: "Vui lòng nhập tên",
        label_birthday: "Ngày sinh",
        place_birthday: "Vui lòng nhập ngày sinh của bạn",
        message_birthday: "Vui lòng nhập ngày sinh",
        label_idType: "Loại giấy tờ",
        tip_cardMain: "Chụp hoặc tải lên ảnh mặt trước của thẻ ID",
        tip_cardBack: "Chụp hoặc tải lên ảnh ở mặt sau của ID",
        tip_cardMain1: "Chụp hoặc tải lên ảnh mặt trước của giấy phép lái xe",
        tip_cardBack1: "Chụp hoặc tải lên hình ảnh đằng sau bằng lái xe của bạn",
        tip_cardMain2: "Chụp hoặc tải lên ảnh hộ chiếu",
        tip_cardBack2: "Chụp hoặc tải lên ảnh hộ chiếu",
        label_password: "Mật khẩu",
        place_password: "Vui lòng nhập mật khẩu",
        message_password: "Vui lòng nhập mật khẩu",
        label_crmpsd: "Xác nhận mật khẩu",
        place_crmpsd: "Vui lòng nhập lại mật khẩu",
        message_crmpsd: "Vui lòng nhập lại mật khẩu",
        label_email: "Thư điện tử",
		place_email: "Địa chỉ Email",
		message_email: "Vui lòng nhập hộp thư",
        label_captcha: "Mã xác nhận",
		place_captcha: "Vui lòng nhập CAPTCHA",
		message_captcha: "Vui lòng nhập CAPTCHA",
		get_captcha: "Nhận CAPTCHA",
        label_phone: "Điện thoại",
		place_phone: "Vui lòng nhập số điện thoại",
		message_phone: "Vui lòng nhập số điện thoại",
        labelAmount: "Số lượng",
        placeAmount: "Vui lòng nhập số tiền",
        messageAmount: "Vui lòng nhập số tiền",
        labelWalletName: "Tên ví",
		messageWalletName: "Vui lòng nhập tên ví",
		placeWalletName: "Vui lòng nhập tên ví",
		labelWalletAddress: "Địa chỉ ví",
		messageWalletAddress: "Vui lòng nhập địa chỉ ví",
		placeWalletAddress: "Vui lòng nhập địa chỉ ví",
        labelWithdrawAccount: "Tài khoản rút tiền",
        placeWithdrawAccount: "Tài khoản rút tiền",
        messageWithdrawAccount: "Tài khoản rút tiền",
		placeTimeLimit: "Vui lòng nhập giới hạn thời gian",
		messageTimeLimit: "Vui lòng nhập giới hạn thời gian",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Úc",
        pakistan: "Pakistan",
        austria: "Áo",
        bahrain: "Bahrain",
        belgium: "Bỉ",
        bosnia_and_Herzegovina: "Bosnia và Herzegovina",
        brazil: "Brazil",
        brunei: "Vương quốc Bru-nây",
        bulgaria: "Bulgaria",
        cambodia: "Campuchia",
        canada: "Canada",
        cameroon: "Ca-mơ-run",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia (Hrvatska)",
        cyprus: "Síp",
        czech_Republic: "Cộng hòa Séc",
        denmark: "Đan Mạch",
        dominican_Republic: "Cộng hòa Dominican",
        egypt: "Ai Cập",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Phần Lan",
        france: "Pháp",
        georgia: "Georgia",
        germany: "Đức",
        ghana: "Ghana",
        greece: "Hy Lạp",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hồng Kông",
        hungary: "Hungary",
        iceland: "Cộng hòa Ai-xơ-len / Iceland",
        ireland: "Cộng Hòa Ai-len / Ái Nhĩ Lan",
        italy: "Ý",
        india: "Ấn Độ",
        indonesia: "Indonesia",
        israel: "Người israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Nhật Bản",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Hàn Quốc",
        kuwait: "Cô-oét / Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Cộng hòa Dân chủ nhân dân Lào",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Ma Cao",
        macedonia: "Bắc Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova, Cộng hòa",
        monaco: "Monaco",
        mongolia: "Mông Cổ",
        montenegro: "Montenegro",
        morocco: "Ma-rốc",
        myanmar: "Myanmar",
        netherlands: "Hà Lan",
        new_Zealand: "New Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Na Uy",
        oman: "Ô-man",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Ba Lan",
        portugal: "Bồ Đào Nha",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Nga",
        republic_of_Trinidad_and_Tobago: "Trinidad và Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Ả Rập Saudi",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "Nam Phi",
        spain: "Tây Ban Nha",
        sri_Lanka: "Sri Lanka",
        sweden: "Thụy Điển",
        switzerland: "Thụy Sĩ",
        taiwan_China: "Đài Loan",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania, Cộng hòa Thống nhất",
        thailand: "Thái Lan",
        turkey: "Thổ Nhĩ Kỳ",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Các Tiểu Vương Quốc Ả Rập Thống Nhất",
        united_Kingdom: "Vương quốc Anh",
        united_States: "Hoa Kỳ",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Việt Nam",
        afghanistan: "Afghanistan",
        angola: "Ăng-gô-la",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Bêlarut",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bôlivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Cộng hòa Dominican",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Mũi Verde",
        cayman_Islands: "Quần đảo Cayman",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: "Công-Gô",
        democratic_Republic_of_the_Congo: "Cộng hòa Dân chủ Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Phi-gi",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Vương quốc Anh",
        cote_d_Ivoire: "Cote d'Ivoire",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libyan Arab Jamahiriya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mô-ri-xơ / Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Nigeria",
        north_Korea: "North Korea",
        reunion: "Réunion",
        san_Marino: "San Marino",
        senegal: "Sê-nê-gan",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Swaziland",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "U.S. Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Serbia",
        zambia: "Zambia",
        zimbabwe: "Cộng hòa Zimbabwe",
        china: "Trung Quốc đại lục",
	}
}
